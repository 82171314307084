import React, {useEffect, useState} from "react";
import Forms from "../../../bricks/form/Forms";
import TestQuestionAdministration from "../testQuestion/TestQuestionAdministration";
import {useAppData} from "../../../context/AppDataContext";
import Loading from "../../../bricks/Loading";
import Modal from "../../../bricks/Modal";
import OtherTestModalContent from "./OtherTestModalContent";

import "./OtherTestAdministration.css";

const OtherTestAdministration = () => {
    const {selectedPeriod, setError, period} = useAppData();
    const [showModal, setShowModal] = useState(false);
    const [selectedOtherTest, setSelectedOtherTest] = useState(null);
    const [otherTestList, setOtherTestList] = useState();
    const [testNumbers, setTestNumbers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState();
    const [modalAction, setModalAction] = useState(null);

    useEffect(() => {
        if (period) {
            setOtherTestList(period?.otherTestList?.map(test => ({value: test._id, label: test.name})) || []);
            setIsLoading(false);
        }
    }, [period]);

    const handleModalOpen = (action) => {
        setModalAction(action); // Nastavit 'create' nebo 'edit'
        setShowModal(true);
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (err) {
            setError(err);
            return null;
        } else {
            return (
                <div className="wrapper">
                    <Modal show={showModal} onHide={() => setShowModal(false)} header="Nahrát otázky">
                        <OtherTestModalContent/>
                    </Modal>
                    <div className="header">
                        <h2>Nastavení ostatních testů</h2>
                        <div className="controls">
                            <Forms.EditIcon title="Upravit" onClick={() => handleModalOpen('edit')}/>
                            <Forms.AddIcon title="Vytvořit" onClick={() => handleModalOpen('create')}/>
                        </div>
                    </div>
                    <Forms.Select inline label="Vyberte test: " colWidth="col-3" options={otherTestList} emptyOption
                                  value={selectedOtherTest} onChange={(_, testId) => setSelectedOtherTest(testId)}/>
                    {
                        selectedOtherTest &&
                        <TestQuestionAdministration key={selectedOtherTest}
                                                    type="otherTest"
                                                    testId={selectedOtherTest}
                                                    hideInfo
                        />
                    }
                </div>
            )
        }
    }

    return renderContent();
};

export default OtherTestAdministration;
