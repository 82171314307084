import React, {useState} from "react";
import {onChange} from "../../../config/Tools";
import Forms from "../../../bricks/form/Forms";
import {axiosInstance} from "../../../bricks/axios";
import {API} from "../../../config/Config";
import {useAppData} from "../../../context/AppDataContext";

import "./LanguageMutationModalContent.scss";

const LanguageMutationModalContent = ({languageMutation, onDelete, onSubmit, onHide}) => {
    const {setError, setSuccess} = useAppData();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(languageMutation || {values: {}});

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            let response;
            if (formData._id) {
                const {method, url} = API.updateLanguageMutation(formData._id);
                response = await axiosInstance[method](url, formData);
                setSuccess("Překlad úspěšně aktualizován");
            } else {
                const {method, url} = API.createLanguageMutation;
                response = await axiosInstance[method](url, formData);
                setSuccess("Překlad úspěšně vytvořen");
            }
            onSubmit(response.data);
            onHide();
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDelete = async () => {
        if (window.confirm(`Opravdu chcete smazat překlad "${formData.code}"?`)) {
            try {
                const {method, url} = API.deleteLanguageMutation(formData._id);
                await axiosInstance[method](url);
                setSuccess("Překlad smazán");
                onDelete(formData._id);
                onHide();
            } catch (e) {
                setError(e);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className="LanguageMutationModalContent">
            <Forms.Text value={formData.code} name="code" label="Kód mutace" onChange={onChange(setFormData)}/>
            <Forms.NestedForm value={formData.values} name="values" onChange={onChange(setFormData)}>
                <Forms.Textarea name="cz" disabled={isLoading}/>
                <Forms.Textarea name="us" disabled={isLoading}/>
                <Forms.Textarea name="ae" disabled={isLoading}/>
                <Forms.Textarea name="cn" disabled={isLoading}/>
                <Forms.Textarea name="ru" disabled={isLoading}/>
                <Forms.Textarea name="ua" disabled={isLoading}/>
                <Forms.Textarea name="vn" disabled={isLoading}/>
            </Forms.NestedForm>
            <div className="modal-footer">
                <div>
                    <Forms.Button onClick={handleDelete} dark label="Smazat" disabled={isLoading}/>
                </div>
                <div>
                    <Forms.Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                    <Forms.Button onClick={handleSubmit} label="Uložit" disabled={isLoading}/>
                </div>
            </div>
        </form>
    );

}

export default LanguageMutationModalContent;