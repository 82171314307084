import React, {useState, useEffect, useContext, createContext} from "react";
import DataGrid, {textEditor} from "react-data-grid";
import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import {API} from "../../../config/Config";
import Loading from "../../../bricks/Loading";
import Forms from "../../../bricks/form/Forms";
import Modal from "../../../bricks/Modal";
import LanguageMutationModalContent from "./LanguageMutationModalContent";

const FilterContext = createContext();

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

const LanguageMutationAdministration = () => {
    const {setError, setSuccess, selectedPeriod} = useAppData();
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    const [selectedLanguageMutation, setSelectedLanguageMutation] = useState();
    const [filters, setFilters] = useState({code: '', enabled: true});
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {method, url} = API.loadLanguageMutations;

                let {data} = await axiosInstance[method](url);
                data = data.map(item => ({...item, ...item.values}));
                setRows(data);
                setFilteredRows(data);

                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        if (selectedPeriod) {
            fetchData();
        }
    }, [selectedPeriod]);

    const handleFieldUpdate = nestedKey => {
        return async (updatedRow, {key}, index) => {
            try {
                const body = {[nestedKey || key]: updatedRow[key]};
                const {method, url} = API.updateLanguageMutation(updatedRow._id);
                const {data: updated} = await axiosInstance[method](url, body);
                // update rows
                setRows(rows => {
                    const newRows = [...rows];
                    newRows[index] = {...updated, ...updated.values};
                    return newRows;
                });

                setSuccess(<label>Jazyková mutace <strong>{updatedRow.code}</strong> úspěšně aktualizována.</label>);
            } catch (e) {
                setError(e);
            }
        }
    }

    const handleOpenEditModal = row => {
        setSelectedLanguageMutation(row);
        setShowModal(true);
    }

    const filterColumnClassName = 'filter-cell';

    const FilterRenderer = ({tabIndex, column, children}) => {
        const filters = useContext(FilterContext);

        return (
            <>
                <div>{column.name}</div>
                {filters.enabled && <div>{children({tabIndex, filters})}</div>}
            </>
        );
    }

    const columns = [
        {
            key: "app",
            name: "Aplikace",
            width: 120,
            formatter: ({row}) => row.app.join(", ")
        },
        {
            key: "code",
            width: 200,
            name: "Kód mutace",
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate("code"),
            renderCell: ({row}) => <label className="name"><Forms.EditIcon
                onClick={() => handleOpenEditModal(row)}/>{row.code}</label>,
            headerCellClass: filterColumnClassName,
            renderHeaderCell: (p) => <FilterRenderer {...p}>
                {({filters, ...rest}) => (
                    <input
                        {...rest}
                        value={filters[p.column.key]}
                        onChange={(e) =>
                            setFilters({
                                ...filters,
                                [p.column.key]: e.target.value
                            })
                        }
                        onKeyDown={inputStopPropagation}
                    />
                )}
            </FilterRenderer>
        },
        {
            key: "cz",
            name: "Česky",
            width: 200,
            updateFn: handleFieldUpdate("values.cz"),
            renderEditCell: textEditor
        },
        {
            key: "us",
            name: "Anglicky",
            width: 200,
            updateFn: handleFieldUpdate("values.us"),
            renderEditCell: textEditor
        },
        {
            key: "ru",
            name: "Rusky",
            width: 200,
            updateFn: handleFieldUpdate("values.ru"),
            renderEditCell: textEditor
        },
        {
            key: "ae",
            name: "Arabsky",
            width: 200,
            updateFn: handleFieldUpdate("values.ae"),
            renderEditCell: textEditor
        },
        {
            key: "vn",
            name: "Vietnamsky",
            width: 200,
            updateFn: handleFieldUpdate("values.vn"),
            renderEditCell: textEditor
        },
        {
            key: "cn",
            name: "Čínsky",
            width: 200,
            updateFn: handleFieldUpdate("values.cn"),
            renderEditCell: textEditor
        },
        {
            key: "ua",
            name: "Ukrajinksy",
            width: 200,
            updateFn: handleFieldUpdate("values.ua"),
            renderEditCell: textEditor
        }
    ];

    useEffect(() => {
        if (rows) {
            let newFilteredRows = rows
                .filter(row => {
                    return Object.keys(filters).every((key) => {
                            return key !== "enabled" && filters[key] ? row[key].toLowerCase().includes(filters[key].toLowerCase()) : true;
                        }
                    )
                });
            setFilteredRows(newFilteredRows);
        }
    }, [rows, filters]);

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else {
            const updateRow = (updatedRows, {column, indexes}) => {
                column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
            }

            const rowKeyGetter = row => row._id;
            return (
                <FilterContext.Provider value={filters}>
                    <DataGrid
                        className="rdg-light bordered"
                        style={{height: 700}}
                        rowKeyGetter={rowKeyGetter}
                        columns={columns}
                        rows={filteredRows}
                        onRowsChange={updateRow}
                        headerRowHeight={filters.enabled ? 70 : undefined}
                    />
                </FilterContext.Provider>
            );
        }
    };

    const handleUpdateTranslation = (newItem) => {
        if (modalAction === "create") {
            setRows(rows => [...rows, {...newItem, ...newItem.values}]);
        } else {
            setRows(rows => rows.map(row => {
                if (row._id === newItem._id) {
                    return {...newItem, ...newItem.values};
                }
                return row;
            }));

        }
    }

    const handleModalOpen = (action) => {
        setModalAction(action); // Nastavit 'create' nebo 'edit'
        setShowModal(true);
    }

    const handleDelete = id => {
        setRows(rows => rows.filter(r => r._id !== id));
    }

    const modalHeader = modalAction === 'edit' ? 'Upravit překlad' : 'Vytovřit překlad';
    return (
        <div className="LanguageMutationAdministration">
            <Modal show={showModal} onHide={() => setShowModal(false)} header={modalHeader}>
                <LanguageMutationModalContent languageMutation={modalAction === 'edit' ? {} : selectedLanguageMutation}
                                              onSubmit={handleUpdateTranslation} onDelete={handleDelete}/>
            </Modal>
            <div className="header">
                <h2>Překlady aplikace</h2>
                <div className="controls">
                    <Forms.AddIcon title="Vytvořit" onClick={() => handleModalOpen('create')}/>
                </div>
            </div>
            {renderContent()}
        </div>
    );
};

export default LanguageMutationAdministration;
