import React from "react";
import Forms from '../../../bricks/form/Forms';

import "./TestDefinitionItem.scss";

const TestDefinitionItem = ({item, onChange, isLastItem, index}) => {
    const handleInputChange = (name, value) => {
        onChange({
            ...item,
            [name]: value
        });
    };

    return (
        <div className="TestDefinitionItem">
            <p>Test {index+1}:</p>
            {!isLastItem && (
                <Forms.Number
                    name="min"
                    value={item.min || ''}
                    onChange={handleInputChange}
                    min={0}
                />
            )}
            {isLastItem && (
                <Forms.Number
                    name="max"
                    value={item.max || ''}
                    onChange={handleInputChange}
                />
            )}
        </div>
    );
}

export default TestDefinitionItem;