import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {TranslationProvider} from "./context/LanguageContext";
import {AuthProvider} from "./context/AuthContext";
import {AppDataProvider} from "./context/AppDataContext";

// react-toastify
import 'react-toastify/dist/ReactToastify.css';

// react-data-grid
import 'react-data-grid/lib/styles.css';
import {AxiosWrapper} from "./bricks/axios";

// react-draft-wysiwyg
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <TranslationProvider>
            <AuthProvider>
                <AxiosWrapper/>
                <AppDataProvider>
                    <App/>
                </AppDataProvider>
            </AuthProvider>
        </TranslationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
