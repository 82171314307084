import React, {useEffect, useState} from "react";
import moment from "moment";
import {API} from "../../../config/Config";
import {onChange, renderDateTime} from "../../../config/Tools";
import Forms from '../../../bricks/form/Forms';
import Loading from "../../../bricks/Loading";
import useTranslation from "../../../hooks/useTranslation";
import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import {toast} from "react-toastify";

import "./UserDetailModalContent.scss";

const UserDetailModalContent = ({userId, onHide, updateUser, statusOptions, nationalityOptions}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const {setError, setSuccess, selectedPeriod, setInfo} = useAppData();
    const [formData, setFormData] = useState({});
    const [overwriteFormData, setOverwriteFormData] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                setIsLoading(true);
                const {method, url} = API.loadUserData(userId);
                const {data} = await axiosInstance[method](url);
                const {user, testResultList, courseRegistrationList} = data;
                const courseRegistrationMap = _mapByPeriod(courseRegistrationList, "object");
                const testResultMapTmp = _mapByPeriod(testResultList);
                const testResultMap = {};
                Object.keys(testResultMapTmp)
                    .sort((a, b) => {
                        return parseInt(a.replace("/", "")) > parseInt(b.replace("/", "")) ? -1 : 1;
                    })
                    .forEach(period => {
                        testResultMap[period] = testResultMapTmp[period]
                            .sort((a, b) => new Date(b.createdAt) > new Date(a.createdAt) ? -1 : 1)
                            .map((testResult, i) => {
                                    testResult.test = testResult.testType === "sortingTest" ? "Rozřazovací test" : "Ostatní";
                                    return testResult;
                                }
                            );
                    });
                setFormData(user);
                setUserData({testResultMap, courseRegistrationMap});
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
        userId && load();
    }, [userId]);

    const _mapByPeriod = (array, returnType = "array") => {
        if (!array) return {};
        return array.reduce((acc, value) => {
            if (returnType === "array") {
                if (acc[value.period]) {
                    acc[value.period].push(value);
                } else {
                    acc[value.period] = [value];
                }
            } else {
                acc[value.period] = value;
            }
            return acc;
        }, {});
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            let response;
            if (formData._id) {
                const {method, url} = API.updateUser(userId);
                response = await axiosInstance[method](url, formData);
                setSuccess("Uživatel upraven");
                updateUser('update', response.data);
            } else {
                const {method, url} = API.createUser;
                response = await axiosInstance[method](url, formData);
                setSuccess("Uživatel vytvořen");
                updateUser('create', response.data);
            }
            onHide();
        } catch (e) {
            setError(e);
        }
    }

    const _generateRecoveryString = async () => {
        try {
            const {method, url} = API.generatePasswordRecoveryLink(userId);
            const {data: recoveryString} = await axiosInstance[method](url);
            setInfo("Odkaz na obnovu hesla úspěšně vygenerován");
            setFormData({...formData, recoveryString});
        } catch (e) {
            setError(e);
        }
    }

    const _deleteTest = async (_id) => {
        try {
            const {method, url} = API.deleteTestResult(_id);
            await axiosInstance[method](url);
            setSuccess('Test byl úspěšně smazán');
        } catch (e) {
            setError(e);
        }

        const testResultMap = {...userData.testResultMap};
        Object.entries(testResultMap).forEach(([period, testResultList]) => {
            testResultMap[period] = testResultList.filter(testResult => testResult._id !== _id);
        });
        setUserData({...userData, testResultMap});
    }

    const _renderTestResultRows = (testResultList) => {
        return testResultList.map(testResult => {
            return (
                <div key={testResult._id}>
                    <label/>
                    <label>{testResult.test}</label>
                    <label>{testResult.result}</label>
                    <label>{moment(testResult.createdAt).format('DD.MM.yyyy hh:mm')}</label>
                    <label>{moment(testResult.updatedAt).format('DD.MM.yyyy hh:mm')}</label>
                    <label><Forms.DeleteIcon title="Smazat test" onClick={() => _deleteTest(testResult._id)}/></label>
                </div>
            );
        })
    }

    const _renderTestRows = (testResultMap) => {
        const result = Object.entries(testResultMap).map(([period, testResultList]) => {
            return (
                <div key={period}>
                    <h5>{period}</h5>
                    {_renderTestResultRows(testResultList)}
                </div>
            );
        });
        return React.Children.toArray(result);
    }

    const renderPasswordSection = () => {
        return (
            <div className="row">
                <div className="col-md-4">
                    <Forms.Switch name="sendResetPasswordEmail" value={formData.sendResetPasswordEmail}
                                  onChange={onChange(setFormData)}/>
                </div>
                {!formData.sendResetPasswordEmail
                && <>
                    <div className="col-md-4">
                        <Forms.Text type="password" name="password" value={formData.password}
                                    onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Text type="password" name="passwordConfirmation" value={formData.passwordConfirmation}
                                    onChange={onChange(setFormData)}/>
                    </div>
                </>}
            </div>
        );
    }

    const _renderUserAttributes = () => {
        if (!formData) return null;
        const sexOptions = [
            {value: 'female', label: t('female')},
            {value: 'male', label: t('male')}
        ];
        const recoveryLink = formData.recoveryString;
        return (
            <div className="user-info">
                <h3>Základní informace</h3>
                <div className="row">
                    <div className="col-md-4">
                        <Forms.Text required name="name" value={formData.name} onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Text required name="surname" value={formData.surname} onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Select required name="sex" options={sexOptions} value={formData.sex}
                                      onChange={onChange(setFormData)}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Forms.Select required name="nationality" value={formData.nationality}
                                      options={nationalityOptions}
                                      onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Select required name="residenceStatus" value={formData.residenceStatus}
                                      options={statusOptions}
                                      onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Text required name="mobile" value={formData.mobile} onChange={onChange(setFormData)}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Forms.Text required name="email" value={formData.email} onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Date required name="dateOfBirth" value={formData.dateOfBirth}
                                    onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4">
                        <Forms.Text
                            title="Unikátní variabilní symbol bude automaticky vegenerován po dokončení registrace uživatele"
                            name="varSymbol" value={formData.varSymbol} disabled/>
                    </div>
                </div>
                {!formData._id && renderPasswordSection()}
                <div className="row">
                    <div className="col-md-12">
                        <Forms.Textarea name="note" value={formData.note} onChange={onChange(setFormData)}/>
                    </div>
                    <div className="col-md-4 info">
                        <label className="label">Registrován</label>
                        <span
                            className="value">{formData._id ? moment(formData.createdAt).format('DD.MM.yyyy hh:mm') : '-'}</span>
                    </div>
                    <div className="col-md-4 info">
                        <label className="label">{t('lastSignIn')}</label>
                        <span
                            className="value">{formData._id ? moment(formData.lastSignIn).format('DD.MM.yyyy hh:mm') : '-'}</span>
                    </div>
                    <div className="col-md-4 info">
                        <label className="label">{t('passwordRecovery')}</label>
                        {recoveryLink &&
                        <div className="reset-link">
                            <Forms.Text disabled value={recoveryLink}/>
                            <Forms.CopyIcon title="Zkopírovat do schránky"
                                            onClick={() => copyRecoveryLink(recoveryLink)}/>
                        </div>
                        }
                        <Forms.Button onClick={_generateRecoveryString} label="Vygenerovat"/>
                    </div>
                </div>
            </div>
        );
    }

    const copyRecoveryLink = (string) => {
        navigator.clipboard.writeText(string);
        toast.info('Link zkopírován');
    }

    const _renderTestAttributes = () => {
        return (
            <div className="test-info">
                <h3>Výsledky testů</h3>
                <div>
                    <div>
                        <label>{t("period")}</label>
                        <label>{t("test")}</label>
                        <label>{t("result")}</label>
                        <label>{t("createdAt")}</label>
                        <label>{t("updatedAt")}</label>
                    </div>
                </div>
                {_renderTestRows(userData.testResultMap)}
            </div>
        );
    }

    const _getState = (value) => {
        if (value) {
            return "Ano";
        } else if (value === false) {
            return "Ne";
        } else {
            return '-';
        }
    }

    const _renderCourseRows = () => {
        const result = Object.entries(userData.courseRegistrationMap).map(([period, courseRegistration]) => {
            const className = courseRegistration.passed === true ? "passed" : " failed";
            return (
                <div key={period} className={`course-row ${className}`}>
                    <label>{period}</label>
                    <label>{courseRegistration.course.code}</label>
                    <label>{_getState(courseRegistration.isAlternate)}</label>
                    <label>{_getState(courseRegistration.paymentCredited)}</label>
                    <label>{courseRegistration.level ? courseRegistration.level : '-'}</label>
                    <label>{moment(courseRegistration.createdAt).format('DD.MM.yyyy hh:mm')}</label>
                    <label>{_getState(courseRegistration.passed)}</label>
                    <label>{renderDateTime(courseRegistration?.paymentCreditedId?.paymentData?.date)}</label>
                    <label>{renderDateTime(courseRegistration?.paymentRefundedId?.paymentData?.date)}</label>
                </div>
            );
        });
        return React.Children.toArray(result);
    }

    const _renderCourseAttributes = () => {
        return (
            <div className="course-info">
                <h3>Přehled kurzů</h3>
                <div>
                    <label>Perioda</label>
                    <label>Kód kurzu</label>
                    <label>Náhradník</label>
                    <label>Zaplaceno</label>
                    <label>Úroveň kurzu</label>
                    <label>Vytvořeno</label>
                    <label>Prošel</label>
                    <label>Platba přijata</label>
                    <label>Platba vrácena</label>
                </div>
                {_renderCourseRows()}
            </div>
        );
    }

    const hasOtherTestToDateOverwrite = () => {
        const {course, isAlternate} = userData?.courseRegistrationMap?.[selectedPeriod] || {};
        const testConfig = course?.testConfig;
        return testConfig && isAlternate === false;
    }

    const handleOtherTestChange = (_, val) => {
        setOverwriteFormData({...overwriteFormData, test: val});
    }

    const updateCourseRegistration = async () => {
        const {method, url} = API.updateCourseRegistration(userData.courseRegistrationMap[selectedPeriod]._id);
        const body = {
            overwriteOtherTestConfig: userData.courseRegistrationMap[selectedPeriod].overwriteOtherTestConfig || []
        };
        body.overwriteOtherTestConfig.push(overwriteFormData);
        const {data} = await axiosInstance[method](url, body);

        // update courseRegistration.course.overwriteTestConfig
        const tmp = {...userData};
        tmp.courseRegistrationMap[selectedPeriod].overwriteOtherTestConfig = data.overwriteOtherTestConfig;
        setUserData(tmp);
    }

    const deleteOtherTestOverwrite = async (overwriteId) => {
        if (window.confirm('Opravdu chcete smazat přetížený datum pro test?')) {
            const {method, url} = API.updateCourseRegistration(userData.courseRegistrationMap[selectedPeriod]._id);
            const body = {
                overwriteOtherTestConfig: userData.courseRegistrationMap[selectedPeriod].overwriteOtherTestConfig.filter(test => test._id !== overwriteId)
            };
            const {data} = await axiosInstance[method](url, body);

            // update courseRegistration.course.overwriteTestConfig
            const tmp = {...userData};
            tmp.courseRegistrationMap[selectedPeriod].overwriteOtherTestConfig = data.overwriteOtherTestConfig;
            setUserData(tmp);
        }
    };

    const _renderOverwriteOtherTestForm = () => {
        const testConfig = userData.courseRegistrationMap[selectedPeriod].course.testConfig;
        return <div className="other-test-form">
            <h3>Zpřístupnění ostatních testů mimo standartní datum</h3>
            <div className="container">
                <Forms.Select label="Vyberte test" onChange={handleOtherTestChange}
                              value={overwriteFormData?.test || ''}
                              options={testConfig.map(test => ({label: test.customName, value: test._id}))}/>

                <Forms.DateTime value={overwriteFormData?.startDate || ''} onChange={onChange(setOverwriteFormData)}
                                name="startDate"
                                label='overwriteFormData zobrazení testu' disabled={isLoading} required/>

                <Forms.DateTime value={overwriteFormData?.endDate || ''} onChange={onChange(setOverwriteFormData)}
                                name="endDate"
                                label='Konec zobrazení testu' disabled={isLoading} required/>
                <Forms.Button onClick={updateCourseRegistration} label="Uložit"/>
            </div>
            {userData.courseRegistrationMap[selectedPeriod]?.overwriteOtherTestConfig?.length ? <div>
                <table className="other-test">
                    <thead>
                    <tr>
                        <th>Test</th>
                        <th>Začátek</th>
                        <th>Konec</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userData.courseRegistrationMap[selectedPeriod].overwriteOtherTestConfig
                        .filter(i => i && i)
                        .map((test, index) => <tr key={index}>
                            <td>{test.test}</td>
                            <td>{moment(test.startDate).format('DD.MM.yyyy HH:mm')}</td>
                            <td>{moment(test.endDate).format('DD.MM.yyyy HH:mm')}</td>
                            <td>{test.customMessageTestCompleted}</td>
                            <td>
                                <button onClick={() => deleteOtherTestOverwrite(test._id)}>Smazat</button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>

            </div> : null}
        </div>
    };

    const _renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (userData) {
            return (
                <>
                    {_renderUserAttributes()}
                    {formData._id && _renderCourseAttributes()}
                    {formData._id && _renderTestAttributes()}
                </>
            )
        }
    }

    const handleDelete = async () => {
        if (window.confirm(`Opravdu chcete smazat uživatele "${formData.name} ${formData.surname}"?`)) {
            try {
                const {method, url} = API.deleteUser(userId);
                await axiosInstance[method](url);
                setSuccess("Uživatel smazán");
                updateUser('delete', formData);
                onHide();
            } catch (e) {
                setError(e);
            }
        }
    }

    return (
        <>
            <form className="UserDetailModalContent" onSubmit={onSubmit}>
                {_renderContent()}
                <div className="modal-footer">
                    <div>
                        {formData._id && updateUser &&
                        <Forms.Button onClick={handleDelete} label="Smazat uživatele" disabled={isLoading}/>}
                    </div>
                    <div>
                        <Forms.Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                        {updateUser &&
                        <Forms.Button type="submit" label={formData._id ? 'Uložit' : 'Vytvořit'} disabled={isLoading}/>}
                    </div>
                </div>
            </form>
            {hasOtherTestToDateOverwrite() && _renderOverwriteOtherTestForm()}
        </>
    );
}

export default UserDetailModalContent;
