import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Tools} from "../../../config/Tools";
import * as $ from 'jquery';
import {Config} from "../../../config/Config";
import {useAppData} from "../../../context/AppDataContext";
import {axiosInstance} from "../../../bricks/axios";

import "./CourseModal.css";
import Button from "../../../bricks/form/Button";

const CourseModalContent = ({refreshCourseList}) => {
    const [course, setCourse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [period, setPeriod] = useState(null);
    const _buttonRef = useRef(null);

    const {selectedPeriod, appConfig} = useAppData();

    useEffect(() => {
        const fetchData = async () => {
            const loadedPeriod = await axiosInstance.loadPeriod(selectedPeriod);
            setIsLoading(false);
            setPeriod(loadedPeriod);
        };

        fetchData();
    }, [selectedPeriod])

    const openModal = async (id = null) => {
        let course = {
            code: "",
            level: "",
            courseDateList: [{day: 0, start: "10:00", end: "11:00"}],
            lector: "",
            note: "",
            startDate: new Date().toISOString(),
            endDate: new Date().toISOString(),
            type: [],
            capacity: 0,
            period: this.props.selectedPeriod,
            place: ""
        };
        if (id) {
            course = await axiosInstance.fetch(`api/private/course/${id}`);
        }
        this.setState({course}, () =>
            $("#courseModal").modal("show")
        );
    }

    const _handleSubmit = async (event) => {
        event.preventDefault();
        let body = this.state.course;

        this.setState({isLoading: true}, async () => {
            this._buttonRef.setDisabled(true).setLoading(true);
            try {
                body = JSON.stringify(body);
                const isNew = !(this.state.course && this.state.course._id);
                const method = isNew ? "PUT" : "POST";
                await axiosInstance.fetch("api/private/course", {method, body});
                this.props.refreshCourseList();
                this._buttonRef.setDisabled(false).setLoading(false);
                this.setState({
                    error: null,
                    course: null
                });
                this._resetModal();
            } catch (e) {
                const error = Tools.processError(e);
                this.setState({error}, () =>
                    this._buttonRef.setDisabled(false).setLoading(false)
                )
            }
        })
    }

    const show = () => {
        $("#courseModal").modal('hide');
    }

    const _resetModal = () => {
        $("#courseModal").modal('hide');
        $(".modal-backdrop").remove();
    }

    const handleChange = (ref) => {
        this.setState(prevState => {
            const course = prevState.course;
            course[ref.getName()] = ref.getValue();
            return {course};
        });
    }

    const _renderAppInputs = () => {
        let inputList = {...Config.courseAttributes};
        inputList = Object.entries(inputList).reduce((acc, [key, val]) => {
            val.label = this.getLsiValue(val.label || key);
            acc[key] = val;
            return acc;
        }, {});
        Object.keys(inputList.type.options).map(key => (inputList.type.options[key] = this.getLsiValue(Config.courseTypes[key])));
        inputList.period.options = appConfig.periodList.map(period => ({[period]: period}));
        inputList.level.options = period.levelPointsConfig.map(({_id, level}) => ({[_id]: level}));
        const result = Tools.renderInputs(inputList, this.state.course, this.handleChange);

        // render courseDate inputs
        this._addCourseDateList(result);
        result.push(<Button onClick={this._addCourseDate} label={"Přidat termín"} type="button"/>);
        return React.Children.toArray(result);
    }

    const _updateCourseDate = (e, index, key) => {
        e.persist();
        this.setState(prevState => {
            const course = prevState.course;
            course.courseDateList[index][key] = key === "day" ? parseInt(e.target.value) : e.target.value;
            return {course};
        })
    }

    const _addCourseDate = () => {
        this.setState(prevState => {
            const course = prevState.course;
            course.courseDateList.push({day: 0, start: "10:00", end: "11:00"});
            return {course};
        });
    }

    const _removeCourseDate = (index) => {
        this.setState(prevState => {
            const course = prevState.course;
            course.courseDateList.splice(index, 1);
            return {course};
        })
    }

    const _addCourseDateList = (rows) => {
        rows.push(<h5 className={this.getClassName("courseDateHeader")}>{this.getLsiValue("courseDates")}</h5>);

        const options = Object.entries(Config.days).map(([key, val]) =>
            <option value={key}>{this.getLsiValue(val)}</option>
        );
        this.state.course.courseDateList.forEach((term, i) => {
                rows.push(<div className={this.getClassName("courseDateList")}>
                    <select onChange={e => this._updateCourseDate(e, i, "day")} value={term.day}>
                        {React.Children.toArray(options)}
                    </select>
                    <input onChange={e => this._updateCourseDate(e, i, "start")} value={term.start} type="time"/>
                    <input onChange={e => this._updateCourseDate(e, i, "end")} value={term.end} type="time"/>
                    {this.state.course.courseDateList.length > 1 &&
                    <Button onClick={() => this._removeCourseDate(i)} label={"Odebrat"}/>}
                </div>)
            }
        );
    }

    const render = () => {
        if (this.state.course) {
            this._inputRefs = [];
            const modalHeader = (this.state.course && this.state.course._id) ? "Editovat kurz" : "Vytvořit kurz";
            return (
                <div className={`modal fade`} id="courseModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <form onSubmit={this._handleSubmit}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">{modalHeader}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className={`modal-body ${this.getClassName("modalBody")}`}>
                                    {this._renderAppInputs()}
                                </div>
                                <div className="modal-footer">
                                    <Button dark type="closeModal" label={this.getLsiValue("close")}/>
                                    <Button ref={ref => this._buttonRef = ref} type="submit" label="Uložit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

CourseModalContent.propTypes = {
    courseId: PropTypes.string
};

export default CourseModalContent;