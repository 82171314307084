import React, {useEffect, useState} from "react";
import Forms from "../../../bricks/form/Forms";
import {useAppData} from "../../../context/AppDataContext";
import Loading from "../../../bricks/Loading";
import {axiosInstance} from "../../../bricks/axios";
import {API} from "../../../config/Config";

import "./PaymentChangeAppModal.scss";


const PaymentChangeAppModal = ({paymentData: propsData, onHide, onPaymentAppChange}) => {
    const {setError, setSuccess} = useAppData();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedApp, setSelectedApp] = useState();
    const [selectedPeriod, setSelectedPeriod] = useState();
    const [appConfigList, setAppConfigList] = useState();

    useEffect(() => {
        const init = async () => {
            try {
                const {method, url} = API.listAppConfig;
                const {data} = await axiosInstance[method](url);
                setAppConfigList(data);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        };

        init();
    }, []);

    useEffect(() => {
    }, [propsData]);

    const _renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else {
            const appOptions = appConfigList.map(cfg => ({label: cfg.app, value: cfg.app}));
            const periodOptions = selectedApp
                ? appConfigList?.find(cfg => cfg.app === selectedApp)?.periodList?.map(period => ({
                    label: period,
                    value: period
                }))
                : [];
            return (
                <div className="body">
                    <Forms.Dropdown
                        value={selectedApp}
                        label="Vyberte aplikaci"
                        options={appOptions}
                        emptyOption
                        onChange={(_, val) => {
                            const tmpPeriod = selectedApp;
                            setSelectedApp(val);
                            val !== tmpPeriod && setSelectedPeriod('');
                        }}
                    />
                    {selectedApp && <Forms.Dropdown
                        value={selectedPeriod}
                        label="Vyberte periodu"
                        options={periodOptions.reverse()}
                        emptyOption
                        onChange={(key, val) => setSelectedPeriod(val)}
                    />}
                </div>
            )
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (selectedApp && selectedPeriod) {
            setIsLoading(true);

            try {
                const {method, url} = API.changePaymentApp(propsData._id);
                const {data} = await axiosInstance[method](url, {
                    paymentId: propsData._id,
                    app: selectedApp,
                    period: selectedPeriod
                });
                setSuccess("Platba úspěšně přesunuta");
                onPaymentAppChange(data);
                onHide();
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <form className="PaymentChangeAppModal" onSubmit={onSubmit}>
            {_renderContent()}
            <div className="modal-footer">
                <Forms.Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                <Forms.Button type="submit" label='Uložit' disabled={isLoading}/>
            </div>
        </form>
    );
};

export default PaymentChangeAppModal;