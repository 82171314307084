import React from "react";

const options = [
    {value: true, label: 'Ano'},
    {value: false, label: 'Ne'}
];

const BoolEditor = ({column, row, onRowChange, value, emptyOption = false}) => {

    const handleChange = (e) => {
        const {value} = e.target;
        onRowChange({...row, [column.key]: value}, true);
    }

    return (
        <select onChange={handleChange} value={value || row?.[column.key]}>
            {emptyOption && <option value=""/>}
            {React.Children.toArray(options.map(opt => <option value={opt.value}>{opt.label}</option>))}
        </select>
    )
}

export default BoolEditor;