import React, {useState, useEffect} from "react";
import {uniqueId} from "../../../config/Tools";
import "./MultiCheckboxEditor.css";

const MultiCheckboxEditor = ({options, column, row, onRowChange}) => {
    const [selected, setSelected] = useState(row[column.key]?.map(val => `${val}`));

    // Synchronizujeme lokální stav s daty z props
    // useEffect(() => {
    //     setSelected(row[column.key]);
    // }, [row[column.key]]);

    const handleCheckboxChange = e => {
        const value = e.target.value;
        setSelected(old => {
            let items = [...old]; // Vytváříme novou instanci pole, aby se neporušila imutabilita stavu
            if (e.target.checked) {
                items.push(value);
            } else {
                items = items.filter(item => item !== value);
            }
            return items;
        });
    }

    // Vzhledem k tomu, že měníme stav přímo při kliknutí na checkbox, není tlačítko potřebné
    // Ale pokud chcete zachovat tlačítko pro uložení, můžete nastavit handleChange takto:
    const handleChange = () => {
        onRowChange({...row, [column.key]: selected.filter(item => item !== "")}, true);
    }

    const id = uniqueId();
    return (
        <div className="MultiCheckboxEditor">
            {options.map(option => {
                return <div key={`mch_${id}-${option.value}`}>
                    <label>
                        {option.label}
                        <input
                            checked={selected.indexOf(option.value) !== -1}
                            value={option.value}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                            name={id}
                        />
                    </label>
                </div>;
            })}
            <button type="button" onClick={handleChange}>uložit</button>
        </div>
    );
}

export default MultiCheckboxEditor;
