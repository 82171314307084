import React, { useState } from 'react';
import { createPortal } from "react-dom";

const CourseDateEditor = ({ row, onRowChange, onClose }) => {
    // Lokální stav pro dočasné uchování změn
    const [tempCourseDateList, setTempCourseDateList] = useState(row.courseDateList);

    // Funkce pro změnu konkrétního data kurzu v dočasném seznamu
    const handleDateChange = (index, field, value) => {
        const updatedCourseDateList = [...tempCourseDateList];
        updatedCourseDateList[index] = { ...updatedCourseDateList[index], [field]: value };
        setTempCourseDateList(updatedCourseDateList);
    };

    // Funkce pro uložení změn
    const handleSave = (event) => {
        event.preventDefault(); // Zabrání výchozímu chování formuláře
        onRowChange({ ...row, courseDateList: tempCourseDateList }, true);
        onClose(true);
    };

    // Možnosti pro select day
    const dayOptions = [
        { label: 'Pondělí', value: 0 },
        { label: 'Úterý', value: 1 },
        { label: 'Středa', value: 2 },
        { label: 'Čtvrtek', value: 3 },
        { label: 'Pátek', value: 4 },
        { label: 'Sobota', value: 5 },
        { label: 'Neděle', value: 6 },
    ];

    return createPortal(
        <div
            className="dialog"
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    onClose();
                }
            }}
        >
            <dialog open>
                <form onSubmit={handleSave}>
                    {tempCourseDateList.map((date, index) => (
                        <p key={index}>
                            <label>Den:
                                <select
                                    value={date.day}
                                    onChange={(e) => handleDateChange(index, 'day', parseInt(e.target.value))}
                                    required
                                >
                                    {dayOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label>Začátek:
                                <input
                                    type="time"
                                    value={date.start}
                                    onChange={(e) => handleDateChange(index, 'start', e.target.value)}
                                    required
                                />
                            </label>
                            <label>Konec:
                                <input
                                    type="time"
                                    value={date.end}
                                    onChange={(e) => handleDateChange(index, 'end', e.target.value)}
                                    required
                                />
                            </label>
                        </p>
                    ))}
                    <menu>
                        <button type="button" onClick={() => onClose()}>
                            Zrušit
                        </button>
                        <button type="submit">
                            Uložit
                        </button>
                    </menu>
                </form>
            </dialog>
        </div>,
        document.body
    );
};

export default CourseDateEditor;
