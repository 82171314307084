import React, {createContext, useContext, useEffect, useState} from "react";
import {toast} from 'react-toastify';
import useTranslation from "../hooks/useTranslation";
import {useAuth} from "./AuthContext";
import {axiosInstance} from "../bricks/axios";

export const AppDataContext = createContext();

export const AppDataProvider = ({children}) => {
    const [appConfig, setAppConfig] = useState();
    const [selectedPeriod, setSelectedPeriod] = useState();
    const [period, setPeriod] = useState();

    const {isLoggedIn} = useAuth();
    const {t} = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data} = await axiosInstance.get("api/appConfig");
                setAppConfig(data);
                setSelectedPeriod(data.actualPeriod);
                const {data: periodData} = await axiosInstance.get(`/api/period/byPeriod/${data.actualPeriod}`);
                setPeriod(periodData);
            } catch (error) {
                setError(error);
            }
        };

        if (isLoggedIn) {
            fetchData();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data} = await axiosInstance.get(`/api/period/byPeriod/${selectedPeriod}`);
                if (period && data) {
                    setSuccess('Perioda úspěšně změněna');
                    setPeriod(data);
                } else {
                    setError('Chyba. Zkuste aktualizovat okno prohlížeče');
                }
            } catch (error) {
                setError(error);
            }
        };

        if (isLoggedIn && selectedPeriod) {
            fetchData();
        }
    }, [isLoggedIn, selectedPeriod]);

    const setError = e => {
        let msg = e.message;

        if (e.response?.data?.error) {
            toast.error(
                <div>
                    <h3>{t(e.response.data.errorCode)}</h3>
                    <pre>{JSON.stringify(e.response.data.error, null, 2)}</pre>
                </div>, {
                    autoClose: 5000,
                    closeButton: true,
                    html: true
                });
            return;
        }

        if (e.response?.data) {
            if (e.response.data?.errorCode) {
                msg = t(e.response.data.errorCode);
            } else if (e.response.data?.errorMessage) {
                msg = e.response.data.errorMessage;
            }
        }

        toast.error(msg);
    }

    const setInfo = message => toast.info(message);
    const setSuccess = message => toast.success(message);

    return (
        <AppDataContext.Provider
            value={{appConfig, selectedPeriod, period, setSelectedPeriod, setError, setInfo, setSuccess, setAppConfig}}>
            {children}
        </AppDataContext.Provider>
    );
};

export const useAppData = () => {
    return useContext(AppDataContext);
};
