import React from 'react';

export const BoolFormatter = (props) => {
    const name = props.column.key;
    const value = props.row[name];
    // props.column.key === "passed" && console.log(props);
    if (value === null || value === undefined || value === "") {
        return "";
    } else if (value) {
        return "Ano";
    } else {
        return "Ne";
    }
}
