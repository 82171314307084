import React, {useEffect, useState} from "react";
import {API, Config} from "../../../config/Config";
import PeriodModalContent from "./PeriodModalContent";
import FileSaver from "file-saver";
import {useAppData} from "../../../context/AppDataContext";
import Loading from "../../../bricks/Loading";
import {axiosInstance} from "../../../bricks/axios";
import Modal from "../../../bricks/Modal";
import Forms from "../../../bricks/form/Forms";

import "./PeriodAdministration.scss";

const PeriodAdministration = () => {
    const {selectedPeriod, setError, setSuccess, setAppConfig} = useAppData();
    const [isLoading, setIsLoading] = useState(true);
    const [period, setPeriod] = useState();
    const [certificate, setCertificate] = useState();
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [modalAction, setModalAction] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                // load period
                const {method, url} = API.loadPeriod(selectedPeriod);
                const {data} = await axiosInstance[method](url);
                setPeriod(data);

                // period
                const {method: m2, url: u2} = API.loadCertificate(selectedPeriod);
                const res = await axiosInstance[m2](u2);
                setCertificate(res.data);
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }

        if (selectedPeriod) {
            fetchData();
        }
    }, [selectedPeriod]);

    const _formatDateTime = (value) => {
        if (Config.validationMap.ISODate.test(value)) {
            const date = new Date(value);
            const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
            const days = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const months = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            return `${days}.${months}.${date.getFullYear()} ${hours}:${minutes}`;
        }
        return value;
    }


    const _formatDate = (value) => {
        if (Config.validationMap.ISODate.test(value)) {
            const date = new Date(value);
            const days = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const months = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            return `${days}.${months}.${date.getFullYear()}`;
        }
        return value;
    }

    const _renderLevelPointsConfigRows = () => {
        const result = period.levelPointsConfig?.map((level) => (
            <tr key={level._id}>
                <th scope="row">Úroveň</th>
                <td>{level.level}</td>
                <th scope="row">Oficiální úroveň</th>
                <td>{level.officialLevel}</td>
                <th scope="row">Minimum</th>
                <td>{level.min}</td>
                <th scope="row">Maximum</th>
                <td>{level.max}</td>
            </tr>
        ));
        return React.Children.toArray(result)
    }

    const _renderTable = () => {
        const result = Object.entries(Config.periodAttributes).map(([key, options]) => {
                let value = options.type === "datetime" ? _formatDateTime(period[key]) : period[key];
                if (options.type === "date") {
                    value = _formatDate(period[key]);
                }
                return (
                    <tr>
                        <th scope="row">{options.label}</th>
                        <td>{value}</td>
                    </tr>
                );
            }
        );

        return (
            <React.Fragment>
                <table className="table">
                    <tbody>{React.Children.toArray(result)}</tbody>
                </table>

                <br/>
                <h3>Nastavení úrovní</h3>
                <table className="table">
                    <tbody>
                    {_renderLevelPointsConfigRows()}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    const _renderChart = () => {
        const registrationStartDate = new Date(period.registrationStartDate);
        const registrationEndDate = new Date(period.registrationEndDate);
        let registrationStartDateTime = registrationStartDate.getTime();
        let registrationEndDateTime = registrationEndDate.getTime();
        const min = Math.min(registrationStartDateTime, registrationEndDateTime);
        const max = Math.max(registrationStartDateTime, registrationEndDateTime);
        const pointWidth = 100 / (max - min);
        return (
            <div className="wrapper-chart">
                <div>
                    <div className="wrapper-chart-period-bar"
                         style={{width: `${(registrationEndDateTime - registrationStartDateTime) * pointWidth}%`}}>
                        <p style={{color: "green"}}>Registrace do systému</p>
                        <div style={{backgroundColor: "green"}}/>
                        <label
                            style={{color: "green"}}>{`${registrationStartDate.toLocaleDateString()} ${registrationStartDate.toLocaleTimeString()}`}</label>
                        <label
                            style={{color: "green"}}>{`${registrationEndDate.toLocaleDateString()} ${registrationEndDate.toLocaleTimeString()}`}</label>
                    </div>

                </div>
            </div>
        );
    }

    const handleUpdatePeriod = ({period: newPeriod, appConfig}) => {
        if (newPeriod._id === period._id) {
            setPeriod(newPeriod);
        }
        setAppConfig(appConfig);
    }

    const uploadPdf = async () => {
        const formData = new FormData();
        if (file) {
            formData.append('period', selectedPeriod);
            formData.append('file', file);
            try {
                const {method, url} = API.uploadPdf;
                const {data} = await axiosInstance[method](url, formData);
                setCertificate(data);
                setSuccess('PDF certifikát úspěšně nahrán');
            } catch (error) {
                setError(error);
            }
        } else {
            alert("Nejprve vyberte PDF šablonu");
        }
    }

    const downloadPdf = async (filePath) => {
        try {
            const {data} = await axiosInstance.get(filePath, {responseType: "blob"});
            FileSaver.saveAs(data, 'certificate.pdf');
        } catch (error) {
            setError(error);
        }
    }

    const downloadFilledPdf = async () => {
        try {
            const {method, url} = API.downloadCertificate(selectedPeriod);
            const {data} = await axiosInstance[method](url, {responseType: "blob"});
            FileSaver.saveAs(data, 'certifikat-vyplneny.pdf');
        } catch (error) {
            setError(error);
        }
    }

    const _renderPdfForm = () => {
        let pdfFile;
        if (certificate?.filePath) {
            pdfFile = (
                <div>
                    {/*<Forms.Button label="Stáhnout vyplněnou předlohu" onClick={downloadFilledPdf}/>*/}
                    <Forms.Button label="Stáhnout předlohu" onClick={() => downloadPdf(certificate.filePath)}/>
                    {certificate.filePath}
                </div>
            );
        }
        return (
            <div className="pdfForm">
                <div>
                    <h4>PDF certifikát</h4>
                </div>
                {pdfFile}
                <div>
                    <input type="file" onChange={e => setFile(e.target.files[0])} accept="application/pdf"/>
                    <Forms.Button onClick={uploadPdf} label="Nahrát PDF"/>
                </div>
            </div>
        )
    }

    const handleModalOpen = (action) => {
        setModalAction(action); // Nastavit 'create' nebo 'edit'
        setShowModal(true);
    }

    const _renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (period) {
            const modalHeader = modalAction === 'edit' ? 'Upravit periodu' : 'Vytovřit periodu';
            return (
                <React.Fragment>
                    <Modal show={showModal} onHide={() => setShowModal(false)} header={modalHeader}>
                        <PeriodModalContent period={modalAction === 'edit' ? period : {}}
                                            onSubmit={handleUpdatePeriod}/>
                    </Modal>
                    <div className="header">
                        <h2> Nastavení periody {selectedPeriod}</h2>
                        <div className="controls">
                            <Forms.EditIcon title="Upravit" onClick={() => handleModalOpen('edit')}/>
                            <Forms.AddIcon title="Vytvořit" onClick={() => handleModalOpen('create')}/>
                        </div>
                    </div>
                    {_renderTable()}
                    {_renderPdfForm()}

                    {_renderChart()}
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <div className="PeriodAdministration-wrapper">
                {_renderContent()}
            </div>
        </React.Fragment>
    );
}

export default PeriodAdministration;
