import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {Config} from "../config/Config";
import Loading from "../bricks/Loading";
import adminTranslations from "../assets/administration-translations.json";

export const TranslationContext = createContext();

export const TranslationProvider = ({children}) => {
    const getBrowserLang = () => {
        const nav = window.navigator;
        const browserLang = nav.language || nav.userLanguage;
        return Config.browserLanguageMap[browserLang] || "us";
    };

    const [translations, setTranslations] = useState({});
    const [data, setData] = useState(null);
    const [language, setLanguage] = useState(getBrowserLang);
    const [languages, setLanguages] = useState(null);

    useEffect(() => {
        const fetchTranslations = async () => {
            let {data} = await axios.get(`/public/translations.json?t=${new Date().getTime()}`);

            // prepare administration-translations.json
            const formattedTranslations = {};
            adminTranslations.forEach(translation => {
                Object.entries(translation.values).forEach(([language, value]) => {
                    if (!formattedTranslations[language]) {
                        formattedTranslations[language] = {};
                    }
                    formattedTranslations[language][translation.code] = value;
                });
            });

            // merge both translation objects
            for (let lang in formattedTranslations) {
                Object.assign(data[lang], formattedTranslations[lang]);
            }

            setData(data);
            setLanguages(Object.keys(data));
        };
        fetchTranslations();
    }, []);

    useEffect(() => {
        if (data) {
            setTranslations(data[language]);
        }
    }, [language, data]);


    return (
        <TranslationContext.Provider value={{translations, language, setLanguage, languages, rawTranslations: data}}>
            {data ? children : <Loading/>}
        </TranslationContext.Provider>
    );
};
