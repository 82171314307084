import React, {useEffect, useState} from 'react';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import {pdfjs} from 'react-pdf';
import {Document, Page} from 'react-pdf';
import DndContainer from "./DndContainer";
import Forms from "../../../bricks/form/Forms";
import {axiosInstance} from "../../../bricks/axios";
import FileSaver from "file-saver";
import {API} from "../../../config/Config";
import {useAppData} from "../../../context/AppDataContext";

import "./CertificateAdministration.scss";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFViewer({pdfUrl}) {
    return (
        <div>
            <Document file={pdfUrl}>
                <Page pageNumber={1}/>
            </Document>
        </div>
    );
}

function CertificateAdministration() {
    const [positions, setPositions] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [certificateData, setCertificateData] = useState();
    const {selectedPeriod, setSuccess, setError} = useAppData();
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {method, url} = API.loadCertificate(selectedPeriod);
                const {data} = await axiosInstance[method](url);
                setCertificateData(data);
                setPositions(data.positions);

                // load user options
                const response = await axiosInstance.get(`api/user/loadEnrolledInCourse/${selectedPeriod}`);
                setUserList(response.data);
            } catch (e) {
                console.log(e)
            } finally {
                setIsLoading(false);
            }
        }

        selectedPeriod && fetchData();
    }, [selectedPeriod]);

    const handleMoveBox = (variable, left, top, fontSize) => {
        setPositions(prevPositions => ({
            ...prevPositions,
            [variable]: {left, top, fontSize}
        }));
    };

    const savePositions = async () => {
        try {
            await axiosInstance.put(`/api/certificate/${certificateData._id}`, {positions});
            setSuccess('Pozice uloženy');
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeAllowDownload = async (_, bool) => {
        try {
            const {data} = await axiosInstance.put(`/api/certificate/${certificateData._id}`, {allowDownload: bool});
            setCertificateData(data);
            setSuccess('Změna uložena');
        } catch (error) {
            console.log(error);
        }
    }

    const downloadCertificate = async () => {
        try {
            let response;
            if (selectedUser) {
                response = await axiosInstance.get(`/api/certificate/generateUserCertificate/${selectedPeriod}/${selectedUser}`, {responseType: "blob"});
            } else {
                response = await axiosInstance.get(`/api/certificate/generateUserCertificate/${selectedPeriod}`, {responseType: "blob"});
            }
            FileSaver.saveAs(response.data, "certificate.pdf");
        } catch (error) {
            error.response.data.text().then(text => {
                return JSON.parse(text);
            }).then(data => {
                error.response.data = data;
                setError(error);
            }).catch(error => {
                console.error('Error parsing JSON', error);
            });
        }
    }

    const handleUserChange = (_, value) => {
        setSelectedUser(value);
    }

    return (
        <div className="CertificateAdministration">
            {certificateData &&
            <div>
                <DndProvider backend={HTML5Backend}>
                    <DndContainer onMoveBox={handleMoveBox} positions={positions}>
                        <PDFViewer key={certificateData.filePath} pdfUrl={certificateData.filePath}/>
                    </DndContainer>
                </DndProvider>
                <div className="control-section">
                    <div className="left">
                        <Forms.Button label="Uložit pozice" onClick={savePositions}/>

                        <Forms.Checkbox label="Povolit uživatelům stažení certifikátu"
                                        value={certificateData?.allowDownload || false}
                                        onChange={handleChangeAllowDownload}/>
                        <div>
                            <Forms.Button label="Stáhnout vyplněný certifikát" onClick={downloadCertificate}/>
                            <Forms.Select
                                label="Vyberte uživatele"
                                options={userList.map(usr => ({
                                    label: `${usr.name} ${usr.surname}`,
                                    value: usr._id
                                }))}
                                onChange={handleUserChange}
                                value={selectedUser || ''}
                            />
                        </div>
                    </div>
                    <div className="right">
                        <Forms.ArrayOf name="variables" onChange={console.log}>
                            <Forms.Text name="variable" label="Proměnná"/>
                            <Forms.Text name="fontSize" label="Velikost fontu"/>
                        </Forms.ArrayOf>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default CertificateAdministration;
