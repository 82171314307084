import React, {useState, useEffect} from "react";
import Button from "../../../bricks/forms/Button";
import {API} from "../../../config/Config";
import {useAppData} from "../../../context/AppDataContext";
import {axiosInstance} from "../../../bricks/axios";
import Forms from "../../../bricks/form/Forms";
import {onChange} from "../../../config/Tools";
import Loading from "../../../bricks/Loading";

import "./TestQuestionModal.scss";

const TestQuestionModal = ({question, onSubmit, testId, testType, closeModal}) => {
    const {setError, selectedPeriod} = useAppData();
    const [period, setPeriod] = useState();
    const [questionData, setQuestionData] = useState(question);
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState();

    useEffect(() => {
        setQuestionData(question);
    }, [question]);

    useEffect(() => {
        const fetchData = async () => {
            const {method, url} = API.loadPeriod(selectedPeriod);
            const {data} = await axiosInstance[method](url);
            setPeriod(data);
            setIsLoading(false);
        };

        fetchData();
    }, [selectedPeriod])


    const _handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        // prepare FormData
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', questionData.type);
        formData.append('test', questionData.test);
        testId && formData.append('testId', testId);
        formData.append('testType', testType);
        formData.append('period', selectedPeriod);
        formData.append('group', questionData.group || '');
        formData.append('instructions', questionData.instructions || '');
        formData.append('question', questionData.question);
        formData.append('answerList', JSON.stringify(questionData.answerList));
        try {
            let response;
            if (questionData._id) {
                const {method, url} = API.updateTestQuestions(questionData._id);
                response = await axiosInstance[method](url, formData);
            } else {
                const {method, url} = API.createTestQuestions;
                response = await axiosInstance[method](url, formData);
            }
            onSubmit(response.data);
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    const questionTypes = [
        {label: "Testová otázka", value: 0},
        {label: "Otevřená otázka", value: 1},
        {label: "Se zvukovou nahrávkou", value: 2}
    ];

    const _renderInputs = () => {
        if (questionData) {
            const testOptions = period.sortingTestDefinitionList.map(({order}) => ({label: order, value: order}));
            return (
                <>
                    <Forms.Select options={questionTypes} name="type" label="Typ otázky"
                                  onChange={onChange(setQuestionData)}
                                  value={questionData.type}/>
                    <Forms.Select options={testOptions} name="test" onChange={onChange(setQuestionData)}
                                  value={questionData.test}/>
                    <Forms.Text name="instructions" label="Instrukce" onChange={onChange(setQuestionData)}
                                value={questionData.instructions}/>
                    {
                        questionData.type === 2
                        && <Forms.File accept="audio/*" name="record" onChange={e => setFile(e.target.files[0])}/>
                    }
                    <Forms.Text value={questionData.group} name="group" label="Skupina"
                                onChange={onChange(setQuestionData)}/>
                    <Forms.Text name="question" onChange={onChange(setQuestionData)} value={questionData.question}/>
                    <Forms.ArrayOf name="answerList" onChange={onChange(setQuestionData)}
                                   value={questionData.answerList} label="Nastavení odpovědí"
                                   addButtonLabel="Přidat odpověď">
                        <Forms.Text name="value" label="Odpověď" disabled={isLoading}/>
                        <Forms.Number name="evaluation" label="Body" disabled={isLoading}/>
                    </Forms.ArrayOf>
                </>
            );
        }
    }

    return (
        <form onSubmit={_handleSubmit}>
            <div className={`modal-body modal-body`}>
                {isLoading ? <Loading/> : _renderInputs()}
            </div>
            <div className="modal-footer">
                <Button dark onClick={closeModal} label="Zavřít"/>
                <Button disabled={isLoading} type="submit" label="Uložit"/>
            </div>
        </form>);
};

export default TestQuestionModal;
