import * as React from "react";
import {uniqueId} from "../../config/Tools";
import useTranslation from "../../hooks/useTranslation";
import Form from 'react-bootstrap/Form';

import "./Switch.scss";

const inputMainProps = ['required', 'name', 'value', 'onChange', 'type', 'title'];

function Switch(props) {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    const id = uniqueId();
    let className = 'Switch FormInput';
    props.className && (className = `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");

    const handleChange = e => {
        props.onChange(e.target.name, e.target.checked);
    }

    return (
        <div className={className}>
            {props.label !== false && <label htmlFor={id}>{props.label || t(props.name)}</label>}
            <Form.Check type="switch" id={id} {...getMainProps(inputMainProps)}
                        disabled={disabled} onChange={handleChange}/>
        </div>
    );
}

export default Switch;