import React, {useContext} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from "./core/Layout";
import {useAppData} from "./context/AppDataContext";
import ErrorMessage from "./bricks/ErrorMessage";
import Loading from "./bricks/Loading";
import Login from "./core/Login";
import {ToastContainer} from "react-toastify";
import UserAdministration from "./core/administration/user/UserAdministration";
import CourseAdministration from "./core/administration/course/CourseAdministration";

import {useAuth} from "./context/AuthContext";

import {TranslationContext} from "./context/LanguageContext";
import StatusAdministration from "./core/administration/status/StatusAdministration";
import NationalityAdministration from "./core/administration/nationality/NationalityAdministration";
import LanguageMutationAdministration from "./core/administration/languageMutation/LanguageMutationAdministration";
import TestQuestionAdministration from "./core/administration/testQuestion/TestQuestionAdministration";
import PeriodAdministration from "./core/administration/period/PeriodAdministration";
import AppConfigAdministration from "./core/administration/appConfig/AppConfigAdministration";
import Dashboard from "./core/administration/overview/Overview";

import OtherTestAdministration from "./core/administration/otherTest/OtherTestAdministration";
import CourseRegistrationAdministration
    from "./core/administration/courseRegistration/CourseRegistrationAdministration";
import PaymentAdministration from "./core/administration/payment/PaymentAdministration";
import EmailAdministration from "./core/administration/email/EmailAdministration";
import NotFoundPage from "./core/NotFoundPage";
import CertificateAdministration from "./core/administration/certificate/CertificateAdministration";

import './App.scss';

const App = () => {
    const {isLoading, isLoggedIn} = useAuth();
    const {error} = useAppData();
    const {translations} = useContext(TranslationContext);

    const renderContent = () => {
        if (isLoading) return <Loading/>;
        if (error) return <ErrorMessage message={error}/>;
        if (!isLoggedIn) return <Login/>;

        return (
            <div>
                <ToastContainer style={{width: "50%"}}/>
                <Routes>
                    <Route element={<Layout/>}>
                        <Route path="/" element={<Dashboard/>}/>
                        <Route path="/appConfig" element={<AppConfigAdministration/>}/>
                        <Route path="/testQuestion" element={<TestQuestionAdministration/>}/>
                        <Route path="/otherTests" element={<OtherTestAdministration />}/>
                        <Route path="/period" element={<PeriodAdministration/>}/>
                        <Route path="/user" element={<UserAdministration/>}/>
                        <Route path="/course" element={<CourseAdministration/>}/>
                        <Route path="/courseRegistration" element={<CourseRegistrationAdministration/>}/>
                        <Route path="/status" element={<StatusAdministration/>}/>
                        <Route path="/nationality" element={<NationalityAdministration/>}/>
                        <Route path="/languageMutation" element={<LanguageMutationAdministration/>}/>
                        <Route path="/sortingTest" element={<TestQuestionAdministration/>}/>
                        <Route path="/payment" element={<PaymentAdministration/>}/>
                        <Route path="/email" element={<EmailAdministration/>}/>
                        <Route path="/certificate" element={<CertificateAdministration/>}/>
                        <Route path="*" element={<NotFoundPage />} />

                    </Route>
                </Routes>
            </div>
        );
    }

    return (
        <BrowserRouter>
            {translations ? renderContent() : <Loading/>}
        </BrowserRouter>
    );
}
export default App;
