import update from 'immutability-helper'
import {useCallback, useEffect, useState} from 'react'
import {useDrop} from 'react-dnd'
import DndElement from "./DndElement";

import "./DndContainer.scss";

const DndContainer = ({children, onMoveBox, positions}) => {
    const [boxes, setBoxes] = useState({
        name: {top: 200, left: 200, title: 'Jméno a příjmení', width: 158.75, height: 42, fontSize: 32},
        code: {top: 180, left: 20, title: 'Kód', width: 62.6, height: 42, fontSize: 18},
        level: {top: 180, left: 20, title: 'Úroveň', width: 62.6, height: 42, fontSize: 18},
        date: {top: 180, left: 20, title: 'Datum', width: 62.6, height: 42, fontSize: 18},
        durationHours: {top: 180, left: 20, title: 'Počet hodin', width: 62.6, height: 42, fontSize: 18},
    });

    useEffect(() => {
        if (positions) {
            const newPositions = {...boxes};
            Object.entries(positions).forEach(([key, positions]) => {
                newPositions[key].top = positions.top - newPositions[key].height / 2;
                newPositions[key].left = positions.left - newPositions[key].width / 2;
            });
            setBoxes(newPositions);
        }
    }, [positions]);

    const moveBox = useCallback(
        (id, left, top) => {
            setBoxes(
                update(boxes, {
                    [id]: {
                        $merge: {left, top},
                    },
                }),
            )
            const centerX = left + (boxes[id].width / 2);
            const centerY = top + (boxes[id].height / 2);
            console.log(`Box ${id} - Center: (${centerX}, ${centerY})`);
            const fontSize = boxes[id].fontSize;
            onMoveBox(id, centerX, centerY, fontSize);
        },
        [boxes, setBoxes],
    )

    const [, drop] = useDrop(
        () => ({
            accept: 'box',
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset()
                const left = Math.round(item.left + delta.x)
                const top = Math.round(item.top + delta.y)
                moveBox(item.id, left, top)
                return undefined
            },
        }),
        [moveBox],
    )

    return (
        <div ref={drop} className="DndContainer">
            {children}
            {Object.keys(boxes).map((key) => {
                const {left, top, title} = boxes[key]
                return (
                    <DndElement
                        key={key}
                        id={key}
                        left={left}
                        top={top}
                        hideSourceOnDrag={true}
                    >
                        {title}
                    </DndElement>
                )
            })}
        </div>
    )
}

export default DndContainer;