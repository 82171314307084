import * as React from 'react';

const NotFoundPage = () => {
    return (
        <div className="NotFoundPage">
            <h3>404 Page not found</h3>
        </div>
    );
};

export default NotFoundPage;