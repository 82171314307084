import Checkbox from './Checkbox';
import LoginButton from './LoginButton';
import Button from './Button';
import Text from './Text';
import Textarea from './Textarea';
import Select from "./Select";
import Date from "./Date";
import Dropdown from "./Dropdown";
import NestedForm from "./NestedForm";
import ArrayOf from "./ArrayOf";
import File from "./File";
import Checkboxes from "./Checkboxes";
import List from "./List";
import Number from "./Number";
import DateTime from "./DateTime";
import Autocomplete from "./Autocomplete";
import EditIcon from "./icons/EditIcon";
import DetailIcon from "./icons/DetailIcon";
import AddIcon from "./icons/AddIcon";
import DeleteIcon from "./icons/DeleteIcon";
import CopyIcon from "./icons/CopyIcon";
import Switch from "./Switch";

import './Forms.scss';

const Forms = {};

Forms.Checkbox = Checkbox;
Forms.Button = Button;
Forms.LoginButton = LoginButton;
Forms.Text = Text;
Forms.Textarea = Textarea;
Forms.List = List;
Forms.Select = Select;
Forms.Date = Date;
Forms.DateTime = DateTime;
Forms.Dropdown = Dropdown;
Forms.NestedForm = NestedForm;
Forms.File = File;
Forms.Checkboxes = Checkboxes;
Forms.Number = Number;
Forms.Autocomplete = Autocomplete;
Forms.ArrayOf = ArrayOf;
Forms.EditIcon = EditIcon;
Forms.DetailIcon = DetailIcon;
Forms.AddIcon = AddIcon;
Forms.DeleteIcon = DeleteIcon;
Forms.CopyIcon = CopyIcon;
Forms.Switch = Switch;

export default Forms;