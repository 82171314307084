import './Checkbox.scss';

const inputMainProps = ['required', 'name', 'placeholder'];

function Checkboxes(props) {

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    let values = props.value;
    if (!Array.isArray(values)) {
        values = [];
    }
    values = values.map(val => val.toString());

    const handleChange = e => {
        let checkedValue = `${e.target.value}`;

        if (e.target.checked) {
            values.push(checkedValue);
        } else {
            const index = values.findIndex(selected => selected === checkedValue);
            values.splice(index, 1);
        }
        props.onChange(e.target.name, values);
    }

    const options = props?.options.map(({label, value}) => ({value: `${value}`, label})) || [];
    return (
        <label className="FormCheckboxes">
            {props.label}
            <div className="choices">
                {options.map(option => (
                    <div key={`chkbx_${option.value}`}>
                        <label>
                            <input checked={values.indexOf(option.value) !== -1} onChange={handleChange}
                                   value={option.value} type="checkbox" {...getMainProps(inputMainProps)}/>
                            {option.label}
                        </label>
                    </div>
                ))}
            </div>
        </label>
    );
}

export default Checkboxes;