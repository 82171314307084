import * as React from "react";
import moment from "moment";
import useTranslation from "../../hooks/useTranslation";
import {uniqueId} from "../../config/Tools";

import './Date.scss';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'min', 'max'];

function DateTime(props) {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (/value|min|max/.test(propName) && value) {
                value = moment(value).format('yyyy-MM-DDTHH:mm');
            }
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleChange = e => {
        props.onChange(e.target.name, e.target.value);
    }

    const disabled = props.disabled || false;
    let className = 'FormInput FormDateTime';
    props.required && (className += " required");

    const key = props.name;
    return (
        <div className={className}>
            {props.label !== false && <label htmlFor={key}>{props.label || t(props.name)}</label>}
            <input id={key} type="datetime-local" {...getMainProps(inputMainProps)} onChange={handleChange}
                   disabled={disabled}/>
        </div>
    );
}

export default DateTime;