import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Forms from "../../../bricks/form/Forms";
import {API} from "../../../config/Config";
import {axiosInstance} from "../../../bricks/axios";
import {onChange} from "../../../config/Tools";
import {useAppData} from "../../../context/AppDataContext";

import "./ActivateOtherTestForCourseModalBody.css";

const ActivateOtherTestForCourseModalBody = ({course, onSubmit, onHide, existingTestData = null}) => {
    const [isLoading, setIsLoading] = useState(false);
    const {period, setSuccess, setError} = useAppData();
    const [formData, setFormData] = useState(existingTestData || {});

    useEffect(() => {
        setFormData(existingTestData || {});
    }, [existingTestData]);

    const _handleSubmit = async () => {
        setIsLoading(true);
        try {
            const body = course;
            body.testConfig ||= [];
            if (existingTestData) {
                body.testConfig = body.testConfig.map(test => {
                    if (test._id === existingTestData._id) {
                        return formData;
                    }
                    return test;
                })
            } else {
                body.testConfig.push(formData);
            }

            const {method, url} = API.updateCourse(course._id);
            const {data} = await axiosInstance[method](url, body);
            setSuccess("Nastavení testu bylo uloženo");
            onSubmit(data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
            onHide();
        }
    };

    const testOptions = period?.otherTestList?.map(test => ({value: test._id, label: test.name})) || [];
    return period && (

        <form onSubmit={_handleSubmit} className="ActivateOtherTestForCourseModalBody">

            <Forms.Select value={formData.test || ''} onChange={onChange(setFormData)} name="test" disabled={isLoading}
                          options={testOptions} emptyOptionkey={Math.random()} label='Vyberte test' required/>

            <Forms.Text value={formData.customName} required onChange={onChange(setFormData)} name="customName"
                        label='Kód překladu (uživatel vidí v přehledu registrace do kurzu)' disabled={isLoading}/>

            <Forms.DateTime value={formData.startDate} onChange={onChange(setFormData)} name="startDate"
                            label='Začátek zobrazení testu' disabled={isLoading} required/>

            <Forms.DateTime value={formData.endDate} onChange={onChange(setFormData)} name="endDate"
                            label='Konec zobrazení testu' disabled={isLoading} required/>

            <Forms.Text value={formData.customMessageTestCompleted} label="Děkovná hláška (kód jazykového překladu)"
                        onChange={onChange(setFormData)} disabled={isLoading} name="customMessageTestCompleted"/>

            <div className="modal-footer">
                <Forms.Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                <Forms.Button onClick={_handleSubmit} label="Uložit" disabled={isLoading}/>
            </div>

        </form>
    );

}

ActivateOtherTestForCourseModalBody.propTypes = {
    course: PropTypes.object,
    onSubmit: PropTypes.func
};

export default ActivateOtherTestForCourseModalBody;