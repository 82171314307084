import * as React from "react";
import ReactSelect from 'react-select';

import useTranslation from "../../hooks/useTranslation";
import {uniqueId} from "../../config/Tools";

import './Select.scss';

function Select({className, disabled, required, value, options, label, name, onChange}) {
    const {t} = useTranslation();
    const id = uniqueId();

    let builtClassName = "FormSelect FormInput dropdown col-50";
    className && (builtClassName = `${builtClassName} ${className}`);
    disabled && (builtClassName += " disabled");
    required && (builtClassName += " required");

    value = options.find(item => item.value === value);

    const handleChange = selected => {
        onChange?.(name, selected.value);
    }

    return (
        <div className={builtClassName}>
            {label !== false && <label htmlFor={id}>{label || t(name)}</label>}
            <ReactSelect required={required} className={builtClassName} defaultValue={value} onChange={handleChange}
                         options={options} placeholder="Vyberte..."/>
        </div>
    );
}

export default Select;