import React from "react";
import TestDefinitionItem from "./TestDefinitionItem";
import Forms from '../../../bricks/form/Forms';
import "./OtherTestItem.scss";

const OtherTestItem = ({test, onTestChange, onFileChange}) => {

    const handleTestDefinitionChange = (index) => (newItem) => {
        const newTestDefinitionList = [...test.testDefinitionList];
        newTestDefinitionList[index] = newItem;
        onTestChange({...test, testDefinitionList: newTestDefinitionList});
    };

    const addTestDefinitionItem = () => {
        const newTestDefinitionList = [...test.testDefinitionList, {order: 0, min: 0, max: 0}];
        onTestChange({...test, testDefinitionList: newTestDefinitionList});
    };

    const handleFieldChange = (name, value) => {
        onTestChange({
            ...test,
            [name]: value
        });
    };

    return (
        <div className="OtherTestItem">
            <h4>Test: <strong>{test.name}</strong></h4>
            <div className="container">
                <Forms.Text
                    name="name"
                    label="Název testu"
                    value={test.name || ''}
                    onChange={handleFieldChange}
                    required
                />
                <Forms.Number
                    name="percentPassedThreshold"
                    label="Hranice pro splnění testu v %"
                    value={test.percentPassedThreshold ?? ''}
                    onChange={handleFieldChange}
                    max={100}
                    min={0}
                />
                <Forms.File
                    onChange={onFileChange}
                    label="Otázky"
                />
            </div>
            {test.testDefinitionList.map((item, index) => (
                <TestDefinitionItem
                    key={index}
                    index={index}
                    item={item}
                    onChange={handleTestDefinitionChange(index)}
                    isLastItem={index === test.testDefinitionList.length - 1}
                />
            ))}
            <Forms.Button onClick={addTestDefinitionItem} label={"Přidat test"}/>
        </div>
    );
}


export default OtherTestItem;