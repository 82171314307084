import React, {useEffect, useState} from "react";
import CanvasJSReact from '../../../assets/canvasjs.react';

import {API} from "../../../config/Config";
import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import Loading from "../../../bricks/Loading";

import "./Overview.css";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const backgroundColorList = [
    "#67e265", "#d84f19", "#558693", "#a326e5", "#c2221f", "#0984a2", "#e93d51", "#fedcc5", "#9385f7", "#cc3b4d",
    "#74b5fc", "#9c475e", "#18b319", "#5533cf", "#b4a369", "#1819c5", "#e29edb", "#64625a", "#790a17", "#2542b4"
];

const Dashboard = () => {
    const {selectedPeriod, setError} = useAppData();
    const [state, setState] = useState({isLoading: true});

    useEffect(() => {
        const fetchData = async () => {
            try {
                let {method, url} = API.loadCourseRegistration(selectedPeriod);
                let {data: courseRegistrationList} = await axiosInstance[method](url);

                let {
                    method: questionMethod,
                    url: questionUrl
                } = API.loadTestQuestionsByPeriodAndType(selectedPeriod, 'sortingTest');
                const {data: testQuestionList} = await axiosInstance[questionMethod](questionUrl);

                // courses
                let {method: coursesMethod, url: coursesUrl} = API.loadCoursesByPeriod(selectedPeriod);
                const {data: courseList} = await axiosInstance[coursesMethod](coursesUrl);

                let {method: periodMethod, url: periodUrl} = API.loadPeriod(selectedPeriod);
                const {data: period} = await axiosInstance[periodMethod](periodUrl);

                let {
                    method: resultMethod,
                    url: resultUrl
                } = API.loadTestResultsByPeriod(selectedPeriod);
                const {data: testResultList} = await axiosInstance[resultMethod](resultUrl);

                let {method: userCountMethod, url: userCountUrl} = API.getUserCount;
                const {data: userCount} = await axiosInstance[userCountMethod](userCountUrl);

                const levelMaxPoints = Math.max(...period.levelPointsConfig.flatMap(level => level.config).map(i => i.max));
                const sortingTestDefinitionListMaxPoints = Math.max(period.sortingTestDefinitionList.map(i => i.max).filter(i => i && i));
                const {testQuestionMaxPoints, testResultPoints} = _getTestQuestionMaxPoints(testQuestionList);
                const testResultDataPoints = _getTestResultDataPoints(testResultList);
                const courseOccupancyData = _getCourseOccupancyData(courseList, courseRegistrationList);

                setState({
                    isLoading: false,
                    courseList,
                    period,
                    sortingTestDefinitionListMaxPoints,
                    levelMaxPoints,
                    testQuestionMaxPoints,
                    testResultPoints,
                    userCount: userCount.count,
                    testResultDataPoints,
                    courseRegistrationList,
                    courseOccupancyData,
                    testResultCount: testResultList.length,
                    onePointWidth: (100 / Math.max(sortingTestDefinitionListMaxPoints, levelMaxPoints, testQuestionMaxPoints))
                });
            } catch (e) {
                console.log(e);
                setState({isLoading: false, error: e});
            }
        }

        if (selectedPeriod) {
            fetchData();
        }
    }, [selectedPeriod, setState]);


    function _getCourseOccupancyData(courseList, courseRegistrationList) {
        const courseMap = {};
        const result = courseList.reduce((acc, course) => {
            courseMap[course._id] = course.code;
            acc.capacityArea[course._id] = course.capacity;
            acc.interestedArea[course._id] = 0;
            acc.alternate[course._id] = 0;
            acc.notAlternate[course._id] = 0;
            return acc;
        }, {
            capacityArea: {},
            interestedArea: {},
            notAlternate: {},
            alternate: {}
        });

        courseRegistrationList.forEach(courseRegistration => {
            result.interestedArea[courseRegistration.course]++;
            if (courseRegistration.hasOwnProperty('isAlternate')) {
                if (courseRegistration.isAlternate) {
                    result.alternate[courseRegistration.course]++;
                } else if (courseRegistration.isAlternate === false) {
                    result.notAlternate[courseRegistration.course]++;
                }
            }
        });

        const getArea = (object) => {
            return Object.entries(object).map(([courseId, value]) => {
                return {
                    y: value,
                    label: courseMap[courseId]
                };
            })
        };
        return {
            capacityArea: getArea(result.capacityArea),
            interestedArea: getArea(result.interestedArea),
            alternate: getArea(result.alternate),
            notAlternate: getArea(result.notAlternate),
        }
    }

    function _getTestResultDataPoints(testResultList) {
        return testResultList.reduce((acc, testResult) => {
            const existingDataPoints = acc.findIndex(dataPoints => dataPoints.x === testResult.result);
            if (existingDataPoints !== -1) {
                acc[existingDataPoints].y++;
            } else {
                acc.push({x: testResult.result, y: 1});
            }
            return acc;
        }, []).sort((a, b) => a.x - b.x)
    }


    function _getTestQuestionMaxPoints(testQuestionList) {
        const testResultPoints = {};
        const testQuestionMaxPoints = testQuestionList
            .filter(t => t.testType === "sortingTest")
            .reduce((acc, testQuestion) => {
                let testQuestionPointsCount = 0;
                if (testQuestion.type === 1) {
                    testQuestionPointsCount = testQuestion.answerList.reduce((acc, answer) => acc = acc + answer.evaluation, 0);
                } else if (testQuestion.type === 0) {
                    testQuestionPointsCount = Math.max(...testQuestion.answerList.map(i => i.evaluation).filter(i => i && i));
                }
                if (testResultPoints[testQuestion.test] === undefined) {
                    testResultPoints[testQuestion.test] = testQuestionPointsCount;
                } else {
                    testResultPoints[testQuestion.test] += testQuestionPointsCount;
                }
                return acc + testQuestionPointsCount;
            }, 0);
        return {testResultPoints, testQuestionMaxPoints};
    }


    function _renderLevelPointsConfigChart(levelPointsConfig) {
        const percentWidth = state.onePointWidth;
        const result = levelPointsConfig.config.map(({level, min, max, _id}, i) => {
            const width = (max - min) * percentWidth;
            const left = min * percentWidth;
            const barStyle = {
                width: `${width}%`,
                left: `${left}%`
            };
            const color = backgroundColorList[i];
            return (
                <div className="dashboard-charts-level-points-bar" key={_id} style={barStyle}>
                    <p style={{color}}>{`Level ${level}`}</p>
                    <div style={{backgroundColor: color}}/>
                    <label style={{color}}>{`${min}b`}</label>
                    <label style={{color}}>{`${max}b`}</label>
                </div>
            );
        });
        return (
            <div style={{height: 100}} key={`${levelPointsConfig._id}`}>
                <p>{levelPointsConfig.name}</p>
                {result}
            </div>
        );
    }


    function _renderTestConfigChart() {
        let left = 0, pointsCount = 0;
        if (state.testResultPoints) {
            const result = Object.entries(state.testResultPoints).map(([test, maxPoints], i) => {
                const width = maxPoints * state.onePointWidth;
                const color = backgroundColorList[i + state.period.levelPointsConfig.length];
                const barStyle = {
                    width: `${width}%`,
                    left: `${left}%`
                };
                left += width;
                return (
                    <div className="dashboard-charts-level-points-bar" key={Math.round(Math.random() * 9999)}
                         style={barStyle}>
                        <p style={{color}}>{`Test ${test}`}</p>
                        <div style={{backgroundColor: color}}/>
                        <label style={{color}}>{`${pointsCount}b`}</label>
                        <label style={{color}}>{`${pointsCount += maxPoints}b`}</label>
                    </div>
                );
            });

            return (
                <div style={{height: 100}}>
                    {result}
                </div>
            );
        }
    }


    function _renderTestResultChart() {
        const testResultOptions = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light2",
            title: {
                text: `Výsledky testů (∑ ${state.testResultCount})`
            },
            axisY: {
                title: "Uživatelé",
                includeZero: true
            },
            axisX: {
                title: "Body z testu",
                interval: 1
            },
            data: [{
                type: "line",
                toolTipContent: "{y} uživatelů s {x} body",
                dataPoints: state.testResultDataPoints
            }]
        };
        return <CanvasJSChart options={testResultOptions}/>;
    }


    function _renderCourseOccupancyChart() {
        if (!state.period.levelPointsConfig) return;
        const courseOccupancyOptions = {
            theme: "light2",
            title: {
                text: "Obsazenost kurzů"
            },
            axisY: {
                includeZero: true,
                suffix: "Míst",
                // interval: 5
            },
            axisX: {
                labelAngle: -30,
                labelFontSize: 12,
                interval: 1
            },
            toolTip: {
                shared: true
            },
            data: [
                {
                    type: "area",
                    name: "Míst",
                    showInLegend: true,
                    dataPoints: state.courseOccupancyData.capacityArea
                },
                {
                    type: "area",
                    name: "Zájemců",
                    showInLegend: true,
                    dataPoints: state.courseOccupancyData.interestedArea
                },
                {
                    type: "area",
                    name: "Náhradníků",
                    showInLegend: true,
                    dataPoints: state.courseOccupancyData.alternate
                },
                {
                    type: "area",
                    name: "Zapsáno",
                    showInLegend: true,
                    dataPoints: state.courseOccupancyData.notAlternate
                }
            ]
        };
        return (
            <div className="dashboard-charts-occupancy-chart">
                <CanvasJSChart options={courseOccupancyOptions}/>
            </div>
        );
    }

    function _renderCourseConfigChart() {
        const courseListAcc = {};
        if (!state.period.levelPointsConfig) return;
        const levelDefinition = state.period.levelPointsConfig.reduce((acc, levelPointsConfig) => {
            acc[levelPointsConfig._id] = {
                ...levelPointsConfig,
                count: 0
            };
            courseListAcc[levelPointsConfig._id] = [];
            return acc;
        }, {});
        const levelCourseList = state.courseList.reduce((acc, course) => {
            const level = levelDefinition[course.level]?._id;
            if (level) {
                acc[level].push(course);
            }
            return acc;
        }, courseListAcc);
        let height = 0;
        const result = Object.entries(levelCourseList).map(([level, courseList], i) => {
            const {min, max} = levelDefinition[level];
            const width = (max - min) * state.onePointWidth;
            const barStyle = {
                width: `${width}%`,
                left: `${min * state.onePointWidth}%`
            };
            let totalTop = 0;
            const color = backgroundColorList[i];
            const labelStyle = {
                color,
                position: "relative",
                top: totalTop + 60
            };
            const columnCourseList = courseList.map(course => {
                const top = `${totalTop += 20}px`;
                return (
                    <div className="dashboard-charts-level-points-bar" key={Math.round(Math.random() * 9999)}
                         style={{...barStyle, top}}>
                        <p style={labelStyle}>{`Kurz ${course.code}`}</p>
                        {totalTop === 20 && <div style={{backgroundColor: color}}/>}
                        {totalTop === 20 && <label style={{color}}>{`${min}b`}</label>}
                        {totalTop === 20 && <label style={{color}}>{`${max}b`}</label>}
                    </div>
                );
            });
            height < totalTop && (height = totalTop);
            return React.Children.toArray(columnCourseList);
        });
        return (
            <div style={{height: height + 60}}>
                {result}
            </div>
        );
    }

    function _renderContent() {
        if (state.isLoading) {
            return <Loading/>;
        } else if (state.error) {
            setError(state.error);
        } else {
            return (
                <div>
                    <h1>Přehled aplikace</h1>
                    <div className="dashboard-charts">
                        {_renderTestConfigChart()}
                        {state.period?.levelPointsConfig?.map(cfg => _renderLevelPointsConfigChart(cfg))}
                        {_renderCourseConfigChart()}
                        {_renderTestResultChart()}
                        {_renderCourseOccupancyChart()}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="dashboard-wrapper">
            {_renderContent()}
        </div>
    );
};

export default Dashboard;
