import React from "react";
import moment from "moment";

export const Tools = {

    getLocalDateString(date) {
        if (date) {
            if (typeof date === "string") {
                date = new Date(date);
            }
            return date.toLocaleDateString();
        }
    },

    getLocalDateTimeString(date) {
        if (date) {
            if (typeof date === "string") {
                date = new Date(date);
            }
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
        }
    },

    processError(e) {
        const {errorCode, paramMap} = e;
        if (errorCode && paramMap) {
            return {errorCode, paramMap};
        }
        return {errorCode: "unexpectedInternalError", paramMap: JSON.stringify(e)}
    },

    getCourseDateRows(config, item, getLsiValue) {
        return Object.entries(config).map(([key, {getLsiKeyByValue}]) => {
            const value = getLsiKeyByValue ? getLsiValue(getLsiKeyByValue(item[key])) : item[key];
            return (
                <tr>
                    <th scope="row">{getLsiValue(key)}</th>
                    <td>{value}</td>
                </tr>
            );
        });
    },

    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    },

    getLoading() {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status"/>
            </div>
        );
    },

    objectId() {
        const rnd = (val) => Math.floor(val).toString(16);
        return rnd(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => rnd(Math.random() * 16));
    },

    getDateFromISODate(date) {
        date = new Date(date);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return `${year}-${month}-${dt}`;
    }
};

export const uniqueId = (prefix = 'id-') => prefix + Math.random().toString(16).slice(-8);

export const onChange = (setFormData) => (key, val) => {
    setFormData(prevState => ({
            ...prevState,
            [key]: val
        }
    ));
}

export const renderDateTime = (date) => date ? moment(date).format('DD.MM.yyyy hh:mm') : '';

export const formatPrice = (value, toFixed = 2) => value.toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
