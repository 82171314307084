import * as React from "react";
import useComponentVisible from "../../hooks/useComponentVisible";

import './Dropdown.scss';

function Dropdown({options, label, onChange, name, value}) {
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const _handleSelect = selected => {
        setIsComponentVisible(false);
        onChange(name, selected);
    }

    const _renderOptions = () => {
        const optionsHtml = options.map(option =>
            <span onClick={() => _handleSelect(option.value)}>
                {option.label}
            </span>
        );
        return (
            <div className="FormDropdown-container">
                {React.Children.toArray(optionsHtml)}
            </div>
        );
    }

    return (
        <div ref={ref} className="FormDropdown">
            <label onClick={() => setIsComponentVisible(true)}>{label}{value && ` - ${value}`}</label>
            <div className="options">
                {isComponentVisible && _renderOptions()}
            </div>
        </div>
    );

}

export default Dropdown;