import React, {useState, useEffect, useContext, createContext} from "react";
import DataGrid from "react-data-grid";
import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import {API, Config} from "../../../config/Config";
import useTranslation from "../../../hooks/useTranslation";
import SelectEditor from "../../react-data-grid/editors/SelectEditor";
import Loading from "../../../bricks/Loading";
import {TranslationContext} from "../../../context/LanguageContext";
import MultiCheckboxEditor from "../../react-data-grid/editors/MultiCheckboxEditor";

const FilterContext = createContext();

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

const NationalityAdministration = () => {
    const {languages} = useContext(TranslationContext);
    const {t} = useTranslation();
    const {setError, setSuccess, selectedPeriod} = useAppData();
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [filters, setFilters] = useState({nationality: '', enabled: true});
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {method, url} = API.loadNationalities;
                const {data} = await axiosInstance[method](url);

                setRows(data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        if (selectedPeriod) {
            fetchData();
        }
    }, [selectedPeriod]);

    const handleFieldUpdate = async (updatedRow, {key}, index) => {
        try {
            const body = {[key]: updatedRow[key]};
            const {method, url} = API.updateNationality(updatedRow._id);
            await axiosInstance[method](url, body);
            // update rows
            setRows(rows => {
                rows[index] = {...rows[index], ...body};
                return rows;
            })
            const list = [...rows];
            list[index] = {...list[index], ...body};
            setRows(list);
            setSuccess(<label>Národnost <strong>{updatedRow.nationality}</strong> úspěšně aktualizována.</label>);
        } catch (e) {
            setError(e);
        }
    }


    useEffect(() => {
        if (rows) {
            const newFilteredRows = rows
                ?.filter(row => {
                    return Object.keys(filters).every((key) => {
                            if (key !== "enabled") {
                                if (typeof filters[key] === 'number') {
                                    return row[key] === filters[key];
                                } else if (filters[key]) {
                                    return row[key].toString().toLowerCase().includes(filters[key].toLowerCase());
                                }
                            }
                            return true;
                        }
                    )
                });
            setFilteredRows(newFilteredRows);
        }
    }, [rows, filters]);

    const languageOptions = languages.map(lang => ({label: lang, value: lang}));
    const filterColumnClassName = 'filter-cell';
    const columns = [
        {
            key: "app",
            name: "Aplikace",
            width: "25%"
        },
        {
            key: "nationality",
            name: t("nationality"),
            width: "25%",
            headerCellClass: filterColumnClassName,
            renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            key={"test"}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            )
        },
        {
            key: "languageMutation",
            width: "25%",
            name: "Jazyková mutace",
            updateFn: handleFieldUpdate,
            renderEditCell: (props) => <SelectEditor options={languageOptions} {...props}/>
        },
        {
            key: "type",
            width: "25%",
            name: "Typ",
            renderCell: ({row}) => row?.type.map(type => t(Config.courseTypes[type])).join(", "),
            updateFn: handleFieldUpdate,
            renderEditCell: (props) => <MultiCheckboxEditor
                options={Object.entries(Config.courseTypes).map(([value, type]) => ({
                    label: t(type),
                    value
                }))} {...props}/>
        }
    ];

    const FilterRenderer = ({tabIndex, column, children}) => {
        const filters = useContext(FilterContext);

        return (
            <>
                <div>{column.name}</div>
                {filters.enabled && <div>{children({tabIndex, filters})}</div>}
            </>
        );
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else {
            const updateRow = (updatedRows, {column, indexes}) => {
                column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
            }

            const rowKeyGetter = row => row._id;
            return (
                <FilterContext.Provider value={filters}>
                    <DataGrid
                        className="rdg-light bordered"
                        style={{height: 700}}
                        rowKeyGetter={rowKeyGetter}
                        columns={columns}
                        rows={filteredRows}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        onRowsChange={updateRow}
                        headerRowHeight={70}
                    />
                </FilterContext.Provider>
            );
        }
    };

    return (
        <div className="wrapper">
            <h2>Nastavení národností</h2>
            {renderContent()}
        </div>
    );
};

export default NationalityAdministration;
