import axios from 'axios';
import {useEffect} from "react";
import {useAuth} from "../context/AuthContext";

const axiosInstance = axios.create();

const AxiosWrapper = () => {
    const {isLoggedIn, getToken, renewToken} = useAuth();

    useEffect(() => {
        const requestInterceptor = axiosInstance.interceptors.request.use(
            config => {
                if (isLoggedIn) {
                    const accessToken = getToken();
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axiosInstance.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;

                if (error.response.status === 401 && error.response.data.errorMessage === "JsonWebTokenError" && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const accessToken = await renewToken();
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                    return axiosInstance(originalRequest);
                }
                return Promise.reject(error);
            }
        );

        // Zrušení interceptorů při odmontování komponenty
        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [isLoggedIn]);

    return null;
};

export {axiosInstance, AxiosWrapper};
