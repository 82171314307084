import React, {useEffect, useState} from "react";
import {API} from "../../../config/Config";
import Forms from "../../../bricks/form/Forms";
import FileSaver from "file-saver";
import {useAppData} from "../../../context/AppDataContext";
import {axiosInstance} from "../../../bricks/axios";
import Loading from "../../../bricks/Loading";
import Modal from "../../../bricks/Modal";
import TestQuestionModal from "./TestQuestionModal";

import "./TestQuestionAdministration.scss";

const TestQuestionAdministration = ({type = "sortingTest", testId = null, hideInfo = false}) => {
    const {setError, selectedPeriod} = useAppData();
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionList, setQuestionList] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState();

    useEffect(() => {
        const init = async () => {
            try {
                let response;

                if (testId) {
                    const {method, url} = API.loadTestQuestionsByPeriodAndType(selectedPeriod, 'otherTest', testId);
                    response = await axiosInstance[method](url);
                } else {
                    const {method, url} = API.loadTestQuestionsByPeriodAndType(selectedPeriod, type);
                    response = await axiosInstance[method](url);
                }

                setQuestionList(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (selectedPeriod) {
            init();
        }
    }, [selectedPeriod]);

    const _handleSubmitFile = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('period', selectedPeriod);
        formData.append('testType', type);

        try {
            const {method, url} = API.uploadTestQuestionsFile;
            const {data} = await axiosInstance[method](url, formData);
            setQuestionList(data);
        } catch (error) {
            setError(error);
        }
    }

    const downloadTemplateFile = async () => {
        try {
            const {method, url} = API.downloadTestQuestionTemplateFile;
            const {data} = await axiosInstance[method](url, {responseType: "blob"});
            FileSaver.saveAs(data, 'predloha-testovvych-otazek.xlsx');
        } catch (error) {
            console.log(error);
            setError(error);
        }
    };

    const renderAnswerOptions = answerList => {
        const rows = answerList.map(answer => <tr>
            <td>{answer.value} ({answer.evaluation} bodů)</td>
        </tr>)
        return (
            <table>
                <tbody>
                {React.Children.toArray(rows)}
                </tbody>
            </table>
        );
    }

    const renderTestType = testType => {
        switch (testType) {
            case 1:
                return "Otevřená otázka";
            case 2:
                return "Poslechová otázka";
            default:
                return "Testová otázka";
        }
    }

    const handleEditQuestion = (question) => {
        setSelectedQuestion(question);
        setIsModalOpen(true);
    }

    const renderTableList = () => {
        const rows = questionList.map(question => {
            // create question table by config
            return (
                <tr>
                    <td><Forms.EditIcon onClick={() => handleEditQuestion(question)}/></td>
                    <td>{renderTestType(question.type)}</td>
                    <td>{question.test}</td>
                    <td>{question.group}</td>
                    <td>{question.question}</td>
                    <td>{question.instructions}</td>
                    <td>
                        {question.record &&
                        <audio controls>
                            <source src={question.record.dest + '/' + question.record.filename} type="audio/mpeg"/>
                            Váš prohlížeč nepodporuje audio tag.
                        </audio>
                        }
                    </td>
                    <td>{renderAnswerOptions(question.answerList)}</td>
                </tr>
            );
        });
        return (

            <table className="question-table">
                <thead>
                <tr>
                    <th/>
                    <th>Typ otázky</th>
                    <th>Číslo testu</th>
                    <th>Skupina</th>
                    <th>Otázka</th>
                    <th>Instrukce</th>
                    <th>Nahrávka</th>
                    <th>Odpovědi</th>
                </tr>
                </thead>
                <tbody>
                {React.Children.toArray(rows)}
                </tbody>
            </table>

        );
    }

    const handleQuestionSubmit = updated => {
        setIsModalOpen(false);
        const newQuestionList = [...questionList];
        const existingIndex = newQuestionList.findIndex(q => q._id === updated._id);
        if (existingIndex !== -1) {
            newQuestionList[existingIndex] = updated;
        } else {
            newQuestionList.push(updated);
        }
        setQuestionList(newQuestionList)
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else {
            return (
                <React.Fragment>
                    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                        <TestQuestionModal question={selectedQuestion} testId={testId} testType={type} onSubmit={handleQuestionSubmit}/>
                    </Modal>
                    {!hideInfo && <>
                        <h2>Rozřazovací test</h2>
                        <form onSubmit={_handleSubmitFile}>
                            <table style={{textAlign: 'left'}}>
                                <thead>
                                <tr style={{borderBottom: '1px solid black'}}>
                                    <th> {`Nahrát otázky pro periodu ${selectedPeriod}`}&nbsp; </th>
                                    <td><input name="file" type="file" onChange={e => setFile(e.target.files[0])}/></td>
                                    <td><Forms.Button type="submit" label="Uložit"/></td>
                                </tr>
                                <tr>
                                    <td>POUZE <strong>XLSX</strong> soubor! rozdělení sloupců: 1. otázka, 2. typ
                                        otázky(uzavřená/otevřená),
                                        3. číslo testu (1/2/...)
                                    </td>
                                    <td><Forms.Button label="Stáhnout předlohu" onClick={downloadTemplateFile}/></td>
                                    <td/>
                                </tr>
                                <tr>
                                    <td>další sloupce budou zprcovány jako odpovědi - tzn. 4. sloupec odpověď, 5.
                                        sloupec
                                        bodové ohodnocení,
                                        6. sloupec další možná odpověď, 7. sloupec další možné odhodnocení, ....
                                    </td>
                                    <td/>
                                    <td/>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><strong>PŘI NAČTENÍ OTÁZEK ZE SOUBORU DOJDE KE SMAZÁNÍ VŠECH PŘEDCHOZÍCH OTÁZEK
                                        PRO
                                        VYBRANOU
                                        PERIODU</strong></td>
                                    <td/>
                                    <td/>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                    </>
                    } <br/>
                    <div className="wrapper-button-new">
                        <Forms.Button label="Přidat novou otázku" type="button"
                                      onClick={() => handleEditQuestion({})}/>
                    </div>
                    <div>
                        {renderTableList()}
                    </div>
                </React.Fragment>
            );
        }

    };

    return (
        <div className="TestQuestionAdministration">
            {renderContent()}
        </div>
    );
};

export default TestQuestionAdministration;
