import {Outlet} from 'react-router-dom';
import Header from "./Header";
import AdministrationLinks from "../bricks/AdministrationLinks";

import "./Layout.scss";
import {useEffect} from "react";

const Layout = ({children}) => {
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <div className="layout-container">
            <Header/>
            <div className="content">
                <AdministrationLinks/>
                <main>
                    <Outlet/>
                </main>
            </div>
        </div>
    );
};

export default Layout;