import * as React from 'react';

function DetailIcon({onClick, title}) {

    return (
        <div className="Icon DetailIcon" onClick={onClick} title={title}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path
                    d="M 3 7 A 1.0001 1.0001 0 0 0 2 8 L 2 12 A 1.0001 1.0001 0 0 0 3 13 L 7 13 A 1.0001 1.0001 0 0 0 8 12 L 8 8 A 1.0001 1.0001 0 0 0 7 7 L 3 7 z M 4 9 L 6 9 L 6 11 L 4 11 L 4 9 z M 11 9 A 1.0001 1.0001 0 1 0 11 11 L 47 11 A 1.0001 1.0001 0 1 0 47 9 L 11 9 z M 3 17 A 1.0001 1.0001 0 0 0 2 18 L 2 22 A 1.0001 1.0001 0 0 0 3 23 L 7 23 A 1.0001 1.0001 0 0 0 8 22 L 8 18 A 1.0001 1.0001 0 0 0 7 17 L 3 17 z M 4 19 L 6 19 L 6 21 L 4 21 L 4 19 z M 11 19 A 1.0001 1.0001 0 1 0 11 21 L 47 21 A 1.0001 1.0001 0 1 0 47 19 L 11 19 z M 36 26 C 30.488997 26 26 30.488997 26 36 C 26 41.511003 30.488997 46 36 46 C 38.396508 46 40.597385 45.148986 42.322266 43.736328 L 48.292969 49.707031 L 49.707031 48.292969 L 43.736328 42.322266 C 45.148986 40.597385 46 38.396508 46 36 C 46 30.488997 41.511003 26 36 26 z M 3 27 A 1.0001 1.0001 0 0 0 2 28 L 2 32 A 1.0001 1.0001 0 0 0 3 33 L 7 33 A 1.0001 1.0001 0 0 0 8 32 L 8 28 A 1.0001 1.0001 0 0 0 7 27 L 3 27 z M 36 28 C 40.430123 28 44 31.569877 44 36 C 44 40.430123 40.430123 44 36 44 C 31.569877 44 28 40.430123 28 36 C 28 31.569877 31.569877 28 36 28 z M 4 29 L 6 29 L 6 31 L 4 31 L 4 29 z M 11 29 C 10.448 29 10 29.448 10 30 C 10 30.552 10.448 31 11 31 L 25.101562 31 C 25.426562 30.293 25.817578 29.625 26.267578 29 L 11 29 z M 3 37 A 1.0001 1.0001 0 0 0 2 38 L 2 42 A 1.0001 1.0001 0 0 0 3 43 L 7 43 A 1.0001 1.0001 0 0 0 8 42 L 8 38 A 1.0001 1.0001 0 0 0 7 37 L 3 37 z M 4 39 L 6 39 L 6 41 L 4 41 L 4 39 z M 11 39 C 10.448 39 10 39.448 10 40 C 10 40.552 10.448 41 11 41 L 25.101562 41 C 24.807562 40.361 24.571578 39.693 24.392578 39 L 11 39 z"/>
            </svg>
        </div>
    );
}

export default DetailIcon;