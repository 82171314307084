import {uniqueId} from "../../config/Tools";
import useTranslation from "../../hooks/useTranslation";

import './Checkbox.scss';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder'];

function Checkbox(props) {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === "value") {
                result["checked"] = value;
            } else if (elementProps.indexOf(propName) !== -1) {
                result[propName] = value;
            }
        });
        return result;
    }

    const handleChange = e => {
        props.onChange(e.target.name, e.target.checked);
    }

    let style = props.style || {};
    const id = uniqueId();
    const colWidth = props.colWidth || 25;
    return (
        <label className={`FormCheckbox col-${colWidth}`} style={style} htmlFor={id}>
            <input type="checkbox" {...getMainProps(inputMainProps)} id={id} onChange={handleChange}/>
            {props.label !== false && <label htmlFor={id}>{props.label || t(props.name)}</label>}
        </label>
    );
}

export default Checkbox;