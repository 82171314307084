import React from 'react';
import {Modal} from 'react-bootstrap';

import './Modal.css';

function MyModal({children, ...props}) {
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            {...props}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props?.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {onHide: props.onHide});
                    }
                    return child;
                })}
            </Modal.Body>
            {/*<Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
                <Button onClick={()=>console.log("handleSubmit")}>Close</Button>
            </Modal.Footer>*/}
        </Modal>
    );
}

export default MyModal;
