import React, {useState, useEffect} from 'react';
import {useAppData} from "../../../context/AppDataContext";
import {API} from "../../../config/Config";
import {axiosInstance} from "../../../bricks/axios";
import useTranslation from "../../../hooks/useTranslation";
import moment from 'moment';
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Button from "../../../bricks/form/Button";

import "./EmailAdministration.scss";

const emailConfig = {
    emailTextRegistration: "Po registraci",
    emailTextCourseRegistration: "Po registraci do kurzu",
    emailTextCourseRegistrationFullCourse: "Při registraci do kurzu jako náhradník",
    emailTextCourseRegistrationNotAssigned: "Registrace do kurzu - nezapsán",
    emailTextPaymentCredited: "Po přijetí platby",
    emailTextPaymentRefunded: "Při navrácení platby",
    emailTextPaymentNotRefunded: "Při zamítnutí navrácení platby",
    emailTextBlacklist: "Pokud je uživatel na blacklistu"
};

function EmailAdministration({match}) {
    const [isLoading, setIsLoading] = useState(true);
    const {selectedPeriod, setError, setSuccess} = useAppData();
    const {t, rawTranslations, languages} = useTranslation();
    const [period, setPeriod] = useState();
    const [selectedEmail, setSelectedEmail] = useState('emailTextRegistration');
    const [editorState, setEditorState] = useState();

    useEffect(() => {
        if (rawTranslations && selectedEmail) {
            const editorStateTmp = {};
            languages.forEach(lang => {
                const htmlContent = rawTranslations[lang][selectedEmail] || '<p></p>';
                const contentBlock = htmlToDraft(htmlContent);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    editorStateTmp[lang] = EditorState.createWithContent(contentState);
                } else {
                    editorStateTmp[lang] = EditorState.createEmpty();
                }
            });
            setEditorState(editorStateTmp);
        }
    }, [rawTranslations, selectedEmail]);

    useEffect(() => {
        const init = async () => {
            try {
                const {method, url} = API.loadPeriod(selectedPeriod);
                const {data} = await axiosInstance[method](url);
                setPeriod(data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        selectedPeriod && init();
    }, [selectedPeriod]);


    const handleSubmit = async () => {
        const htmlContent = {};
        languages.forEach(lang => {
            htmlContent[lang] = draftToHtml(convertToRaw(editorState[lang].getCurrentContent()));
        });

        setIsLoading(true);
        try {
            const {method, url} = API.updateLanguageMutationByCode(selectedEmail);
            await axiosInstance[method](url, htmlContent);
            setSuccess(`Překlad úspěšně upraven`);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const renderEmailVariantLinks = () => {
        const result = Object.entries(emailConfig).map(([variant, label]) => {
            let className = "item";
            variant === selectedEmail && (className += " selected");
            return (<label className={className} onClick={() => setSelectedEmail(variant)}>{label}</label>);
        });
        return React.Children.toArray(result)
    }

    const onEditorStateChange = lang => (newEditorState) => {
        setEditorState({...editorState, [lang]: newEditorState});
    }

    const renderForm = () => {
        return (
            <div className="editor-container">
                {
                    React.Children.toArray(languages.map(lang => <div className="editor">
                        <h3>{t(lang)}</h3>
                        <Editor
                            editorState={editorState[lang] || EditorState.createEmpty()}
                            wrapperClassName="wrapperClassName"
                            onEditorStateChange={onEditorStateChange(lang)}
                        />
                    </div>))
                }
                <Button label={"Uložit"} onClick={handleSubmit}/>
            </div>
        );
    }

    if (period) {
        const variant = match?.params?.variant;
        return (
            <div className="EmailAdministration">
                <h2>E-maily {variant && `(${emailConfig[variant]})`}</h2>
                <div className="submenu">
                    {renderEmailVariantLinks()}
                </div>
                <label>Lze využít následující proměnné</label>
                <table className="table">
                    <thead className="thead-dark">
                    <tr>
                        <th>Proměnná</th>
                        <th>Popis</th>
                        <th>Hodnota</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>%registrationStartDate</td>
                        <td>Datum začátku registrace do systému</td>
                        <td>{moment(period.registrationStartDate).format('DD.MM.YYYY hh:mm')}</td>
                    </tr>
                    <tr>
                        <td>%showDrawResultDate</td>
                        <td>Datum zobrazení výsledků slosování</td>
                        <td>{moment(period.showDrawResultDate).format('DD.MM.YYYY hh:mm')}</td>
                    </tr>
                    <tr>
                        <td>%userCourseStartDate</td>
                        <td>Datum začátku kurzu, do kterého se uživatel zapsal</td>
                        <td>např.: 9.3.2019 (bude doplněno podle zapsaného kurzu)</td>
                    </tr>
                    <tr>
                        <td>%userCourseStartTime</td>
                        <td>Čas začátku kurzu, do kterého se uživatel zapsal</td>
                        <td>např.: 14:00 (bude doplněno podle zapsaného kurzu)</td>
                    </tr>
                    <tr>
                        <td>%userCourseCode</td>
                        <td>Kód kurzu, do kterého je užiatel zapsaný</td>
                        <td>např.: AMIF/20/06/ICP-10 (bude doplněno podle zapsaného kurzu)</td>
                    </tr>
                    </tbody>
                </table>

                {renderForm()}
            </div>
        );
    }
}

export default EmailAdministration;
