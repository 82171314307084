import React, {createContext, useContext, useEffect, useState} from "react";
import decode from "jwt-decode";
import {API} from "../config/Config";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const getToken = () => localStorage.getItem('access_token');

    const isTokenExpired = (token) => {
        try {
            const decoded = decode(token);
            const expired = decoded.exp < Date.now() / 1000;
            if (expired) {
                localStorage.removeItem('courseTest');
            }
            return expired; // Checking if token is expired. N
        } catch (err) {
            return false;
        }
    }

    const isUserLoggedIn = () => {
        // check if token is valid
        const access_token = getToken();
        if (access_token) {
            // if (isTokenExpired(access_token)) {
            //     return false;
            // } else {
            return true;
            // }
        }
        return false;
    }

    const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn());

    useEffect(() => {
        const renewAccessTokenIfNeeded = async () => {
            if (isUserLoggedIn()) {
                setIsLoggedIn(true);
                if (isTokenExpired(getToken())) {
                    await renewToken();
                }
            } else {
                logout();
            }
        };

        renewAccessTokenIfNeeded();
    }, []);

    const login = (accessToken, refreshToken) => {
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
        setIsLoggedIn(true);
    };

    const logout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
    };

    const renewToken = async () => {
        const refreshToken = localStorage.getItem('refresh_token');

        // send refresh token to server and get a new access token
        try {
            const response = await axios.post(API.renewToken.url, {refreshToken});
            const newAccessToken = response.data.accessToken;

            // save new access token in local storage
            localStorage.setItem('access_token', newAccessToken);
            return newAccessToken;
        } catch (error) {
            console.error("Failed to renew access token: ", error);
            logout();
            return null;
        }
    };

    return (
        <AuthContext.Provider
            value={{isLoggedIn, login, logout, getToken, renewToken}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
