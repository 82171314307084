import * as React from "react";
import {uniqueId} from "../../config/Tools";
import useTranslation from "../../hooks/useTranslation";

import './Text.scss';
import {forwardRef} from "react";

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'type', 'onKeyPress', 'title'];

const Text = forwardRef((props, ref) => {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (propName === "value" && !value) value = "";
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    const id = uniqueId();
    let className = 'Text FormInput';
    props.className && (className = `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");
    const type = props.password ? 'password' : 'text';

    const handleChange = e => {
        props.onChange(e.target.name, e.target.value);
    }

    return (
        <div className={className}>
            {props.label !== false && <label htmlFor={id}>{props.label || t(props.name)}</label>}
            <input ref={ref} type={type} id={id} autoComplete={props.name} {...getMainProps(inputMainProps)}
                   disabled={disabled} onChange={handleChange}/>
        </div>
    );
})

export default Text;