import * as React from "react";
import Forms from "./Forms";
import {useState} from "react";

import './ArrayOf.css';

function ArrayOf({children, onChange, name, value, label, desc, addButtonLabel}) {
    const [records, setRecords] = useState(value || []);

    const handleChildChange = (recordIndex, childName, childValue) => {
        const newRecords = [...records];
        newRecords[recordIndex][childName] = childValue;
        setRecords(newRecords);
        onChange(name, newRecords);
    };

    const addRecord = () => {
        const newRecord = {};
        React.Children.forEach(children, (child) => {
            newRecord[child.props.name] = '';
        });
        const newRecords = [...records, newRecord];
        setRecords(newRecords);
        onChange(name, newRecords);
    };

    const deleteRecord = (recordIndex) => {
        const newRecords = records.filter((_, index) => index !== recordIndex);
        setRecords(newRecords);
        onChange(name, newRecords);
    };

    return <div className="FormsArrayOf">
        {label && <h4>{label}</h4>}
        {label && <div className="divider"/>}
        {desc && <label className="ArrayOf_desc">{desc}</label>}
        <div>
            {records.map((record, index) => (
                <div key={`FormsArrayOf_${name}_${index}`} className="FormsArrayOf_row">
                    {React.Children.map(children, (child) => (
                        React.cloneElement(child, {
                            value: record[child.props.name],
                            onChange: (childName, childValue) => handleChildChange(index, childName, childValue),
                        })
                    ))}
                    <Forms.DeleteIcon onClick={() => deleteRecord(index)} label={"X"}/>
                </div>
            ))}
            <Forms.AddIcon onClick={addRecord} title={addButtonLabel || "Přidat"}/>
        </div>
    </div>
}

export default ArrayOf;