import React, {useState} from 'react';
import {createPortal} from "react-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import "./AssignPaymentEditor.scss";

const AssignPaymentEditor = ({row, onClose, options, column}) => {
    const [selected, setSelected] = useState(row.user);

    const handleSubmit = () => {
        column.updateFn({...row, user: selected}, {key: 'user'});
    }
    const selectedOption = options.find(option => option.value === selected);

    return createPortal(
        <div
            className="AssignPaymentEditor"
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    onClose();
                }
            }}
        >
            <dialog open>
                <Autocomplete
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={(option) => option.label}
                    style={{width: 300}}
                    value={selectedOption}
                    onChange={(e, val) => setSelected(val?.value)}
                    renderInput={(params) => <TextField {...params} label="Vyberte uživatele" variant="outlined"/>}
                />
                <menu>
                    <button type="button" onClick={() => onClose()}>
                        Cancel
                    </button>
                    <button type="button" onClick={handleSubmit}>
                        Save
                    </button>
                </menu>
            </dialog>
        </div>,
        document.body
    );
};

export default AssignPaymentEditor;
