import * as React from "react";
import moment from "moment";
import {uniqueId} from "../../config/Tools";
import useTranslation from "../../hooks/useTranslation";

import './Date.scss';

const inputMainProps = ['required', 'name', 'value', 'placeholder', 'min', 'max'];

function Date(props) {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            if (/value|min|max/.test(propName) && value) {
                value = moment(value).format('yyyy-MM-DD');
            }
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const handleChange = e => {
        props.onChange(e.target.name, e.target.value);
    }

    const id = uniqueId();
    const disabled = props.disabled || false;
    let className = 'Date FormInput';
    props.className && (className = `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");
    return (
        <label className={className}>
            {props.label !== false && <label htmlFor={id}>{props.label || t(props.name)}</label>}
            <input type="date" {...getMainProps(inputMainProps)} onChange={handleChange} disabled={disabled}/>
        </label>
    );
}

export default Date;