import React, {useState} from "react";
import Forms from "../../../bricks/form/Forms";
import {axiosInstance} from "../../../bricks/axios";
import {API} from "../../../config/Config";
import OtherTestItem from "./OtherTestItem";
import {useAppData} from "../../../context/AppDataContext";

import "./OtherTestModalContent.scss";

const OtherTestModalContent = ({onHide}) => {
    const {setError, setSuccess, selectedPeriod, period} = useAppData();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({name: '', percentPassedThreshold: 0, testDefinitionList: []});
    const [file, setFile] = useState();

    const _handleSubmit = async () => {
        try {
            setIsLoading(true);

            // 1. Create record in period.otherTestConfig
            const {method, url} = API.updatePeriod(period._id);
            let newPeriod = {...period};
            newPeriod.otherTestList.push(formData);
            let response = await axiosInstance[method](url, newPeriod);
            const updatedPeriod = response.data;

            // 2. Upload file with questions
            const body = new FormData();
            body.append('file', file);
            body.append('period', selectedPeriod);
            body.append('testType', "otherTest");
            console.log({testId: updatedPeriod.otherTestList[updatedPeriod.otherTestList.length - 1]._id});
            body.append('testId', updatedPeriod.otherTestList[updatedPeriod.otherTestList.length - 1]._id);
            const {method: m2, url: u2} = API.uploadTestQuestionsFile;
            await axiosInstance[m2](u2, body);
            setSuccess("Test úspěšně vytvořen");
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleTestChange = (newTest) => {
        setFormData(newTest);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0])
    };

    return formData && (
        <form onSubmit={_handleSubmit} className="OtherTestModalContent">
            <OtherTestItem
                test={formData}
                onTestChange={handleTestChange}
                onFileChange={handleFileChange}
            />
            <div className="modal-footer">
                <Forms.Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                <Forms.Button onClick={_handleSubmit} label="Uložit" disabled={isLoading}/>
            </div>
        </form>
    );

}

export default OtherTestModalContent;