import React, {useEffect, useMemo, useState} from "react";
import FileSaver from "file-saver";
import {API, Config} from "../../../config/Config";
import CourseModalContent from "./CourseModalContent";
import {useAppData} from "../../../context/AppDataContext";
import Button from "../../../bricks/form/Button";
import Modal from "../../../bricks/Modal";
import useTranslation from "../../../hooks/useTranslation";
import Loading from "../../../bricks/Loading";
import {axiosInstance} from "../../../bricks/axios";
import DateEditor from "../../react-data-grid/editors/DateEditor";
import DataGrid, {textEditor, SelectColumn} from "react-data-grid";
import moment from 'moment';
import MultiCheckboxEditor from "../../react-data-grid/editors/MultiCheckboxEditor";
import SelectEditor from "../../react-data-grid/editors/SelectEditor";
import LevelEditor from "../../react-data-grid/editors/LevelEditor";
import CourseDateEditor from "../../react-data-grid/editors/CourseDateEditor";

import "./CourseAdministration.scss";

const CourseAdministration = () => {
    const {t} = useTranslation();
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [period, setPeriod] = useState();
    const [editCourseId, setEditCourseId] = useState(null);
    const [filters, setFilters] = useState({code: '', enabled: true});
    const {selectedPeriod, setError, setSuccess} = useAppData();

    useEffect(() => {
        if (selectedPeriod) {
            const fetchData = async () => {
                await loadData();
            };
            fetchData();
        }
    }, [selectedPeriod]);

    async function loadData() {
        try {
            const {method: _method, url: _url} = API.loadCoursesByPeriod(selectedPeriod);
            const {data: courseList} = await axiosInstance[_method](_url);
            setRows(courseList);

            const {method, url} = API.loadPeriod(selectedPeriod);
            const {data: period} = await axiosInstance[method](url);
            setPeriod(period);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleFieldUpdate = async (updatedRow, {key}, index) => {
        try {
            const body = {[key]: updatedRow[key]};
            if (key === "type") {
                body.type = body.type.map(n => parseInt(n));
            }
            const {method, url} = API.updateCourse(updatedRow._id);
            const {data} = await axiosInstance[method](url, body);

            // update rows
            setRows(rows => {
                const tmp = [...rows];
                tmp[index] = data;
                return tmp;
            });
            setSuccess(<label>Kurz <strong>{updatedRow.code}</strong> úspěšně aktualizován.</label>);
        } catch (e) {
            setError(e);
        }
    }

    const preparedLevelMap = period?.levelPointsConfig.reduce((acc, levelConfig) => {
        levelConfig.config.forEach(levelGroup => {
            acc[levelGroup._id] = `${levelConfig.name} (${levelGroup.level})`;
        });
        return acc;
    }, {});

    const levelRenderer = ({row}) => {
        return row?.level?.map(level => preparedLevelMap[level]).join(", ");
    }

    const variantOptions = ["online", "elearning", "in-class"].map(type => ({label: type, value: type}));
    const columns = useMemo(() => [
        SelectColumn,
        {
            key: "code",
            name: t("code"),
            renderEditCell: textEditor,
            width: 150,
            frozen: true,
            editable: true,
            updateFn: handleFieldUpdate,
        },
        {
            key: "level",
            name: t("level"),
            width: 80,
            editable: true,
            renderCell: levelRenderer,
            updateFn: handleFieldUpdate,
            renderEditCell: (props) => <LevelEditor
                levelPointsConfig={period.levelPointsConfig} {...props}/>
        },
        {
            key: "capacity",
            name: t("capacity"),
            width: 80,
            renderEditCell: textEditor,
            renderCell: ({row}) => row.capacity,
            updateFn: handleFieldUpdate
        },
        {
            key: "place",
            name: t('coursePlace'),
            width: 200,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate,
            editable: true,
        },
        {
            key: "floorAndRoom",
            name: t('floorAndRoom'),
            width: 150,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate
        },
        {
            key: "startDate",
            name: t("courseStartDate"),
            width: 150,
            renderCell: ({row}) => moment(row.startDate).format('DD.MM.YYYY'),
            renderEditCell: (props) => <DateEditor {...props}/>,
            updateFn: handleFieldUpdate
        },
        {
            key: "endDate",
            name: t("courseEndDate"),
            width: 150,
            renderCell: ({row}) => moment(row.endDate).format('DD.MM.YYYY'),
            renderEditCell: (props) => <DateEditor {...props}/>,
            updateFn: handleFieldUpdate
        },
        {
            key: "lector",
            name: t("lector"),
            width: 150,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate
        },
        {
            key: "note",
            name: t("note"),
            width: 150,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate
        },
        {
            key: "type",
            name: t("courseType"),
            width: 150,
            updateFn: handleFieldUpdate,
            renderCell: ({row}) => row.type.map(type => t(Config.courseTypes[type])).join(', '),
            renderEditCell: (props) => <MultiCheckboxEditor
                options={Object.entries(Config.courseTypes).map(([value, type]) => ({
                    label: t(type),
                    value
                }))} {...props}/>
        },
        {
            key: "courseDateList",
            name: t("courseDateList"),
            width: 150,
            updateFn: handleFieldUpdate,
            renderCell: ({row}) => row.courseDateList.map(date => `${t(Config.days[date.day])} (${date.start} - ${date.end})`).join(", "),
            renderEditCell: CourseDateEditor,
        },
        {
            key: "durationHours",
            name: t("durationHours"),
            width: 150,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate
        },
        {
            key: "variant",
            name: t("course.variant"),
            width: 150,
            updateFn: handleFieldUpdate,
            renderEditCell: (props) => <SelectEditor emptyOption options={variantOptions} {...props}/>,
        },
    ].map(col => ({resizable: true, ...col})), [period]);

    // const _deleteCourse = async (courseId) => {
    //     try {
    //         const {method, url} = API.deleteCourseById(courseId);
    //         await axiosInstance[method](url);
    //         setRows(courseList => courseList.filter(i => i._id !== courseId));
    //     } catch (e) {
    //         setError(e);
    //     }
    // }

    // const _editCourse = courseId => {
    //     setEditCourseId(courseId);
    //     setShowModal(true);
    // }

    const _handleSubmitFile = async (event) => {
        event.preventDefault();
        const data = new FormData();
        if (file) {
            data.append('file', file);
            data.append('period', selectedPeriod);
            try {
                const {data: courseList} = await axiosInstance.post(`/api/course/uploadFile`, data);
                setRows(courseList);
            } catch (error) {
                setError(error);
            }
        } else {
            alert("Nejprve vyberte soubor s kurzy");
        }
    }

    const filteredRows = useMemo(() => {
        return rows.filter(row => {
            return (
                filters.name ? row.name.toLowerCase().indexOf(filters.name.toLowerCase()) !== -1 : true
            )
        })
    }, [rows, filters, selectedRows]);

    const _renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (!isLoading && (!rows || !period)) {
            return <div>chyba</div>;
        } else {

            const rowKeyGetter = row => row._id;
            const updateRow = (updatedRows, {column, indexes}) => {
                column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
            }

            return (
                <React.Fragment>
                    <h2>Kurzy</h2>
                    <Modal show={showModal} onClose={() => setShowModal(false)}>
                        <CourseModalContent courseId={editCourseId}/>
                    </Modal>
                    <form onSubmit={_handleSubmitFile}>
                        <table style={{textAlign: 'left'}}>
                            <thead>
                            <tr style={{borderBottom: '1px solid black'}}>
                                <th> {`Nahrát kurzy pro periodu ${selectedPeriod}`}&nbsp; </th>
                                <td><input name="file" type="file" onChange={e => setFile(e.target.files[0])}/></td>
                                <td><Button type="submit" label="Uložit"/></td>
                            </tr>
                            <tr>
                                <td>POUZE <strong>XLSX</strong> soubor!
                                </td>
                                <td><Button label="Stáhnout předlohu" onClick={_downloadTemplateFile}/></td>
                                <td/>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>PŘI NAČTENÍ KURZŮ ZE SOUBORU DOJDE KE SMAZÁNÍ VŠECH PŘEDCHOZÍCH KURZŮ PRO
                                    VYBRANOU
                                    PERIODU</strong></td>
                                <td/>
                                <td/>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                    <br/>

                    <Button label="Vytvořit nový kurz" type="button" onClick={() => setShowModal(true)}/>
                    <p style={{color: "red"}}>V případě nevyplnění "patro a místnost" se ve výpisu kurzů nebude
                        zobrazovat</p>
                    <p style={{color: "red"}}>V případě nevyplnění "místo konání" se ve výpisu kurzů nebude
                        zobrazovat</p>
                    <DataGrid
                        className="rdg-light bordered"
                        style={{height: 700}}
                        rowKeyGetter={rowKeyGetter}
                        columns={columns}
                        rows={filteredRows}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        onRowsChange={updateRow}
                        headerRowHeight={filters.enabled ? 70 : undefined}
                    />
                </React.Fragment>
            );
        }
    }

    const _downloadTemplateFile = async () => {
        try {
            const {method, url} = API.downloadCourseTemplateFile;
            const {data} = await axiosInstance[method](url, {responseType: "blob"});
            FileSaver.saveAs(data, 'predloha-kurzu.xlsx');
        } catch (error) {
            setError(error);
        }
    }

    return (
        <div className="CourseAdministration">
            {_renderContent()}
        </div>
    );
}

export default CourseAdministration;