import React, {useState, useEffect} from "react";
import DataGrid from "react-data-grid";
import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import {API} from "../../../config/Config";
import useTranslation from "../../../hooks/useTranslation";
import SelectEditor from "../../react-data-grid/editors/SelectEditor";
import Loading from "../../../bricks/Loading";

const StatusAdministration = () => {
    const {t} = useTranslation();
    const {setError, setSuccess, selectedPeriod} = useAppData();
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {method, url} = API.loadStatuses;
                const {
                    data
                } = await axiosInstance[method](url, {params: {selectedPeriod}});

                setRows(data);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        if (selectedPeriod) {
            fetchData();
        }
    }, [selectedPeriod]);

    const handleFieldUpdate = async (updatedRow, {key}, index) => {
        try {
            const body = {[key]: JSON.parse(updatedRow[key])};
            const {method, url} = API.updateStatus(updatedRow._id);
            await axiosInstance[method](url, body);
            // update rows
            setRows(rows => {
                rows[index] = {...rows[index], ...body};
                return rows;
            })
            const list = [...rows];
            list[index] = {...list[index], ...body};
            setRows(list);
            setSuccess(<label>Status <strong>{updatedRow.type}</strong> úspěšně aktualizován.</label>);
        } catch (e) {
            setError(e);
        }
    }

    const columns = [
        {
            key: "app",
            name: "Aplikace",
            frozen: true,
        },
        {
            key: "type",
            name: "Status",
            frozen: true,
            renderCell: ({row}) => t(row.type)
        },
        {
            key: "acceptable",
            name: "Povolený",
            width: 180,
            renderCell: ({row}) => row.acceptable ? t('yes') : t('no'),
            renderEditCell: (props) => <SelectEditor
                options={[{label: "Ano", value: true}, {label: "Ne", value: false}]} {...props}/>,
            updateFn: handleFieldUpdate
        }
    ];

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else {
            const updateRow = (updatedRows, {column, indexes}) => {
                column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
            }

            const rowKeyGetter = row => row._id;
            return (
                <>
                    <DataGrid
                        className="rdg-light bordered"
                        style={{height: 700}}
                        rowKeyGetter={rowKeyGetter}
                        columns={columns}
                        rows={rows}
                        onRowsChange={updateRow}
                    />
                </>
            );
        }
    };

    return (
        <div className="wrapper">
            <h2>Nastavení statusů pobytu</h2>
            {renderContent()}
        </div>
    );
};

export default StatusAdministration;
