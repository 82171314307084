import React from "react";
import Icon from '@mdi/react';
import {mdiLogout} from '@mdi/js';
import {useAuth} from "../context/AuthContext";
import {useAppData} from "../context/AppDataContext";
import Forms from "../bricks/form/Forms";
import logo from "../assets/header-photo-200.png";

import "./Header.scss";

const Header = () => {
    const {logout} = useAuth();
    const {appConfig, setSelectedPeriod, selectedPeriod} = useAppData();

    const periodSelect = appConfig?.periodList.map(period => ({label: period, value: period})).reverse();

    return (
        <header className="Header">
            {process.env.REACT_APP_ENV === "test" && <h2>TESTOVACÍ PROSTŘEDÍ</h2>}
            <div className="logo-container">
                <img src={logo} alt="logo"/>
                <span><strong>ADMINISTRACE</strong></span>
            </div>
            {appConfig &&
            <Forms.Select options={periodSelect} value={selectedPeriod}
                          onChange={(_, value) => setSelectedPeriod(value)}
                          label={"Aktuální perioda"}/>}
            <div className="logout">
                <Icon onClick={logout} path={mdiLogout} size={"2rem"}/>
            </div>
        </header>
    );
};

export default Header;