import React from "react";
import moment from 'moment';

const SelectEditor = ({column, row, onRowChange}) => {

    const handleChange = (e) => {
        onRowChange({...row, [column.key]: new Date(e.target.value)}, true);
    }

    return (
        <input type="date" onChange={handleChange} value={moment(row[column.key]).format('YYYY-MM-DD')}/>
    )
}

export default SelectEditor;