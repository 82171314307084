import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, Link} from "react-router-dom";

import "./AdministrationLinks.scss";

const links = [
    {label: "Přehled", path: ""},
    {label: "Aplikace", path: "appConfig"},
    {label: "Perioda", path: "period"},
    {label: "Uživatelé", path: "user"},
    {label: "Kurzy", path: "course"},
    {label: "Překlady", path: "languageMutation"},
    {label: "Rozřazovací test", path: "sortingTest"},
    {label: "Ostatení testy", path: "otherTests"},
    {label: "Registrace do kurzů", path: "courseRegistration"},
    {label: "Platby", path: "payment"},
    {label: "Emaily", path: "email"},
    {label: "Certifikáty", path: "certificate"},
    {label: "Národnosti", path: "nationality"},
    // { label: "Přehled komunikace", path: "communicationOverview" },
    // { label: "Dokumenty", path: "document" },
    {label: "Statusy pobytů", path: "status"},
];


const AdministrationLinks = () => {
    const location = useLocation();
    const [activeStyle, setActiveStyle] = useState({left: 0, width: 0});
    const itemRefs = useRef([]);
    const selectedLink = useMemo(() => location.pathname.split("/").pop(), [location]);

    const updateActiveBackground = () => {
        const index = links.findIndex(link => link.path === selectedLink);
        if (index >= 0 && itemRefs.current[index]) {
            const item = itemRefs.current[index];
            setActiveStyle({
                left: item.offsetLeft,
                width: item.offsetWidth,
            });
        }
    };

    useEffect(() => {
        updateActiveBackground();
    }, [selectedLink]);

    return (
        <div className="AdministrationLinks">
            <div className="active-background" style={activeStyle}/>
            {links.map(({label, path}, index) => (
                <Link
                    ref={(el) => (itemRefs.current[index] = el)}
                    key={path}
                    to={path}
                    className={selectedLink === path ? "selected" : ""}
                >
                    {label}
                </Link>
            ))}
        </div>
    );
};
export default AdministrationLinks;
