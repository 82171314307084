import * as React from "react";
import {uniqueId} from "../../config/Tools";
import useTranslation from "../../hooks/useTranslation";

import './Textarea.scss';

const inputMainProps = ['required', 'name', 'value', 'onChange', 'placeholder', 'onKeyPress'];

function Textarea(props) {
    const {t} = useTranslation();

    const getMainProps = (elementProps) => {
        const result = {};
        Object.entries(props).forEach(([propName, value]) => {
            (elementProps.indexOf(propName) !== -1) && (result[propName] = value);
        });
        return result;
    }

    const disabled = props.disabled || false;
    const id = uniqueId();
    let className = `Textarea FormInput`;
    props.className && (className = `${className} ${props.className}`);
    props.disabled && (className += " disabled");
    props.required && (className += " required");

    const handleChange = e => {
        props.onChange(e.target.name, e.target.value);
    }

    return (
        <div className={className}>
            {props.label !== false && <label htmlFor={id}>{props.label || t(props.name)}</label>}
            <textarea id={id} {...getMainProps(inputMainProps)} disabled={disabled} onChange={handleChange}/>
        </div>
    );
}

export default Textarea;