import React, {useState} from "react";
import {onChange} from "../../../config/Tools";
import Forms from "../../../bricks/form/Forms";
import {axiosInstance} from "../../../bricks/axios";
import {API, Config} from "../../../config/Config";
import {useAppData} from "../../../context/AppDataContext";

import "./PeriodModalContent.scss";

const PeriodModalContent = ({period, onSubmit, onHide}) => {
    const {setError, setSuccess} = useAppData();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(period);

    const _handleSubmit = async () => {
        try {
            setIsLoading(true);
            let response;
            if (period._id) {
                const {method, url} = API.updatePeriod(period._id);
                response = await axiosInstance[method](url, formData);
                setSuccess("Perioda úspěšně aktualizována");
            } else {
                const {method, url} = API.createPeriod;
                response = await axiosInstance[method](url, formData);
                setSuccess("Perioda vytvořena");
            }
            onSubmit(response.data);
            onHide();
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    }

    const levelPointGroups = Object.entries(Config.courseTypes).map(([languageGroup, label]) => ({
        label,
        value: parseInt(languageGroup)
    }));

    return (
        <form onSubmit={_handleSubmit} className="PeriodModalForm">
            <div className="row">
                <Forms.Text name="period" onChange={onChange(setFormData)} value={formData.period}
                            disabled={isLoading}/>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Forms.DateTime name="registrationStartDate" onChange={onChange(setFormData)}
                                    value={formData.registrationStartDate} disabled={isLoading}/>
                </div>
                <div className="col-md-6">
                    <Forms.DateTime name="registrationEndDate" onChange={onChange(setFormData)}
                                    value={formData.registrationEndDate} disabled={isLoading}/>
                </div>
            </div>
            <Forms.DateTime name="sortingTestStartDate" onChange={onChange(setFormData)}
                            value={formData.sortingTestStartDate} disabled={isLoading}/>
            <div className="row">
                <div className="col-md-6">
                    <Forms.DateTime name="courseDrawDate" onChange={onChange(setFormData)}
                                    value={formData.courseDrawDate}
                                    disabled={isLoading}/>
                </div>
                <div className="col-md-6">
                    <Forms.DateTime name="showDrawResultDate" onChange={onChange(setFormData)}
                                    value={formData.showDrawResultDate}
                                    disabled={isLoading}/>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <Forms.DateTime name="depositDueDate" onChange={onChange(setFormData)}
                                    value={formData.depositDueDate}
                                    disabled={isLoading}/>
                </div>
                <div className="col-md-6">
                    <Forms.Number name="isUserEligibleForSkipSortingTest" onChange={onChange(setFormData)}
                                  value={formData.isUserEligibleForSkipSortingTest}
                                  label={"Uživatel nemusí vyplňovat vstupní test v případě splnění výstupního testu v předchozích n periodách"}
                                  disabled={isLoading}/>
                </div>
            </div>
            <Forms.ArrayOf name="sortingTestDefinitionList" onChange={onChange(setFormData)}
                           value={formData.sortingTestDefinitionList} label="Nastavení rozřazovacího testu">
                <Forms.Number name="min" disabled={isLoading}/>
                <Forms.Number name="max" disabled={isLoading}/>
            </Forms.ArrayOf>
            <Forms.ArrayOf name="levelPointsConfig" onChange={onChange(setFormData)}
                           value={formData.levelPointsConfig} label="Nastavení úrovní">
                <Forms.Text name="name" label="Název skupiny" disabled={isLoading}/>
                <Forms.Checkboxes options={levelPointGroups} name="acceptableTypes" disabled={isLoading}/>
                <Forms.ArrayOf name="config">
                    <Forms.Text name="level" disabled={isLoading}/>
                    <Forms.Text name="officialLevel" disabled={isLoading}/>
                    <Forms.Number name="order" disabled={isLoading}/>
                    <Forms.Number name="min" disabled={isLoading}/>
                    <Forms.Number name="max" disabled={isLoading}/>
                </Forms.ArrayOf>

            </Forms.ArrayOf>
            <div className="row">
                <div className="col-md-6">
                    <Forms.Number disabled={isLoading} name="userCourseRegistrationLimit"
                                  value={formData.userCourseRegistrationLimit}
                                  onChange={onChange(setFormData)}/>
                </div>
                <div className="col-md-6">
                    <Forms.Date disabled={isLoading} name="paymentCronStartDate" value={formData.paymentCronStartDate}
                                onChange={onChange(setFormData)}/>
                </div>
            </div>
            <Forms.Date disabled={isLoading} name="paymentCronEndDate" value={formData.paymentCronEndDate}
                        onChange={onChange(setFormData)}/>

            <div className="modal-footer">
                <Forms.Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                <Forms.Button onClick={_handleSubmit} label="Uložit" disabled={isLoading}/>
            </div>
        </form>
    );

}

export default PeriodModalContent;