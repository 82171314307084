import React, {createContext, useContext, useEffect, useMemo, useState} from "react";

import DataGrid from "react-data-grid";
import Forms from "../../../bricks/form/Forms";
import Icon from "@mdi/react";
import {mdiSwapHorizontal} from "@mdi/js";
import Loading from "../../../bricks/Loading";
import {API} from "../../../config/Config";
import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import moment from 'moment';
import AssignPaymentEditor from "../../react-data-grid/editors/AssignPaymentEditor";
import {formatPrice} from "../../../config/Tools";
import {exportToCsv} from "../../react-data-grid/export-utils";
import Modal from "../../../bricks/Modal";
import PaymentChangeAppModal from "./PaymentChangeAppModal";

import "./PaymentAdministration.scss";

const FilterContext = createContext();

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

const PaymentAdministration = () => {
    const {setError, selectedPeriod, setSuccess} = useAppData();
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [userMap, setUserMap] = useState({});
    const [selectedPaymentType, setSelectedPaymentType] = useState();
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [filters, setFilters] = useState({counterAccountName: '', varSymbol: '', enabled: true});
    const [filteredRows, setFilteredRows] = useState([]);
    const [accountBalance, setAccountBalance] = useState();

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    const handleFieldUpdate = async (updatedRow, {key}) => {
        try {
            const body = {[key]: updatedRow[key]};
            const {method, url} = API.updatePayment(updatedRow._id);
            await axiosInstance[method](url, body);
            // update rows
            setRows(prevRows => {
                const newRows = [...prevRows];
                const index = newRows.findIndex(row => row.i === updatedRow.i);
                newRows[index] = {...newRows[index], ...body};
                return newRows;
            });
            setSuccess('Platba úspěšně aktualizována.');
        } catch (e) {
            setError(e);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {method, url} = API.loadPaymentsOverview(selectedPeriod);
                const {data} = await axiosInstance[method](url);

                const accountBalance = {
                    incoming: 0,
                    outgoing: 0
                };

                const tmp = data.map((row, i) => {
                    row.i = i + 1;
                    row.amount = row.paymentData.amount;
                    row.counterAccount = row.paymentData?.counterAccount && `${row.paymentData.counterAccount}/${row.paymentData.bankCode}`;
                    row.counterAccountName = row.paymentData?.counterAccountName;
                    row.vs = row.paymentData.vs ? `${row.paymentData.vs}` : '';
                    row.comment = row.paymentData.comment;
                    row.user = row.user?._id;
                    accountBalance[row.type] += row.amount;
                    return row;
                })
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setRows(tmp);
                setFilteredRows(tmp);
                setAccountBalance(accountBalance);

                const {method: m2, url: u2} = API.loadOptionsForPayment(selectedPeriod);
                const response = await axiosInstance[m2](u2);
                const usrOpt = [];
                const usrMap = [];
                response.data.forEach(({_id, name, surname, varSymbol}) => {
                    usrOpt.push({label: `${name} ${surname} (${varSymbol})`, value: _id});
                    usrMap[_id] = `${name} ${surname} (${varSymbol})`;
                });
                setUserOptions(usrOpt);
                setUserMap(usrMap);

                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        selectedPeriod && fetchData();
    }, [selectedPeriod]);

    const filterColumnClassName = 'filter-cell';

    const FilterRenderer = ({tabIndex, column, children}) => {
        const filters = useContext(FilterContext);

        return (
            <>
                <div>{column.name}</div>
                {filters?.enabled && <div>{children({tabIndex, filters})}</div>}
            </>
        );
    }

    const handleModalOpen = row => {
        setModalData(row);
        setShowModal(true);
    }

    const columns = useMemo(() => {
        return userOptions ? [
            {
                key: "i",
                name: "#",
                width: 45
            },
            {
                key: "createdAt",
                name: "Načteno",
                renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY'),
                width: 150
            },
            {
                key: "received",
                name: "Přijato",
                renderCell: ({row}) => moment(row.paymentData.date).format('DD.MM.YYYY'),
                width: 150
            },
            {
                key: "amount",
                name: "Částka",
                width: 100
            },
            {
                key: "counterAccount",
                name: "Protiúčet",
                width: 200
            },
            {
                key: "counterAccountName",
                name: "Název protiúčtu",
                width: 250,
                headerCellClass: filterColumnClassName,
                renderHeaderCell: (p) => <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            },
            {
                key: "vs",
                name: "Var. symbol",
                width: 100,
                headerCellClass: filterColumnClassName,
                renderHeaderCell: (p) => <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            },
            {
                key: "user",
                name: "Přiřazeno k",
                renderEditCell: (props) => <AssignPaymentEditor options={userOptions} {...props}/>,
                updateFn: handleFieldUpdate,
                renderCell: ({row}) => {
                    return <React.Fragment>
                        <Icon size={1} alt="" path={mdiSwapHorizontal}
                              onClick={() => handleModalOpen(row)} cursor="pointer"/>
                        <label>{row.user ? userMap[row.user] : ''}</label>
                    </React.Fragment>;
                }
            },
            {
                key: "comment",
                name: "Komentář",
                width: 300
            }
        ].map(col => ({
            ...col, ...{
                sortable: true,
                resizable: true
            }
        })) : [];
    }, [userOptions]);

    useEffect(() => {
        if (rows) {
            const newFilteredRows = rows
                ?.filter(row => {
                    if (selectedPaymentType) {
                        return selectedPaymentType === "incoming" ? row.paymentData.amount > 0 : row.paymentData.amount < 0;
                    }
                    return true;
                })
                .filter(row => {
                    return Object.keys(filters).every((key) => {
                            return key !== "enabled" && filters[key] ? row[key].toLowerCase().includes(filters[key].toLowerCase()) : true;
                        }
                    )
                });
            setFilteredRows(newFilteredRows);
        }
    }, [rows, filters, selectedPaymentType]);

    let gridElement;
    const _renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (rows) {

            const RowRenderer = ({renderBaseRow, ...props}) => {
                let backgroundColor = "green";
                let color = "black";
                if (!props.row.assignedToUser) {
                    color = "white";
                    backgroundColor = "gray";
                }
                return <div style={{backgroundColor, color}}>{renderBaseRow(props)}</div>;
            };

            const updateRow = (updatedRows, {column, indexes}) => {
                column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
            }

            const rowKeyGetter = row => row._id;
            const headerHeight = document.getElementsByTagName('header')[0].clientHeight;
            const navBarHeight = document.getElementsByClassName('AdministrationLinks')[0].clientHeight;
            const height = window.innerHeight - headerHeight - navBarHeight - 28;

            gridElement = <DataGrid
                className="rdg-light bordered"
                style={{height: `${height}px`}}
                rowKeyGetter={rowKeyGetter}
                columns={columns}
                rows={filteredRows}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                onRowsChange={updateRow}
                headerRowHeight={filters.enabled ? 70 : undefined}
            />;
            return (
                <div className="PaymentAdministration-wrapper-container">
                    {
                        accountBalance &&
                        <div className="account-overview">
                            <div><label>Příchozí platby: </label><span>{formatPrice(accountBalance.incoming)} Kč</span>
                            </div>
                            <div><label>Odchozí platby: </label><span>{formatPrice(accountBalance.outgoing)} Kč</span>
                            </div>
                            <div>
                                <label>Rozdíl: </label><span>{formatPrice(accountBalance.incoming + accountBalance.outgoing)} Kč</span>
                            </div>
                        </div>
                    }
                    <FilterContext.Provider value={filters}>
                        {gridElement}
                    </FilterContext.Provider>
                </div>
            );
        }
    }

    const fetchNewPayments = async () => {
        setIsLoading(true);
        try {
            const {method, url} = API.fetchNewPayments;
            const {data} = await axiosInstance[method](url);
            alert(`nepodařilo se zpracovat: ${data.stats.errors.length}
nových plateb načteno: ${data.stats.totalPaymentsCount}
spárováno pouze s uživateli (bez kurzu): ${data.stats.pairedWithUserOnly}
spárováno kompletně (i s kurzem): ${data.stats.pairedSuccessfully}
nespárováno: ${data.stats.pairFailedCount}`);
            setRows(data.preparedObjects);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false)
        }
    }

    const onPaymentAppChange = updatedPayment => {
        setRows(oldRows => {
            return oldRows.filter(payment => payment._id !== updatedPayment._id);
        });
        setFilteredRows(oldRows => {
            return oldRows.filter(payment => payment._id !== updatedPayment._id);
        });
        // update balance
        setAccountBalance(accountBalance => {
            const newBalance = {...accountBalance};
            if (updatedPayment.type === "incoming") {
                newBalance.incoming -= updatedPayment.paymentData.amount;
            } else if (updatedPayment.type === "outgoing") {
                newBalance.outgoing -= updatedPayment.paymentData.amount;
            }
            return newBalance;
        });
    }

    return (
        <div className="PaymentAdministration-wrapper">
            <h2>Přehled plateb</h2>
            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}
                   header="Přesunout platbu do jiné aplikace">
                <PaymentChangeAppModal paymentData={modalData} onPaymentAppChange={onPaymentAppChange}/>
            </Modal>
            <div className="course-overview-wrapper-payment-config">
                <div className="course-overview-wrapper-payment-buttons-container">
                    <div onClick={() => setSelectedPaymentType("incoming")}
                         className={`payment-button income ${selectedPaymentType === 'incoming' ? 'selected' : ''}`}
                    >Příchozí
                    </div>
                    <div onClick={() => setSelectedPaymentType("outgoing")}
                         className={`payment-button outcome ${selectedPaymentType === 'outgoing' ? 'selected' : ''}`}
                    >Odchozí
                    </div>
                </div>
                <div className="payment-controls">
                    <Forms.Button label="Načíst nové platby" disabled={isLoading} onClick={fetchNewPayments}/>
                    <Forms.Button label="Stáhnout přehled (CSV)" disabled={isLoading}
                                  onClick={() => exportToCsv(gridElement, 'payment.csv')}/>
                </div>
            </div>
            {_renderContent()}
        </div>
    );
};

export default PaymentAdministration;
