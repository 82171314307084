import React, {useEffect, useRef, useState} from 'react';
import Forms from '../bricks/form/Forms';
import {toast, ToastContainer} from 'react-toastify';
import useTranslation from "../hooks/useTranslation";
import {axiosInstance} from "../bricks/axios";
import {API} from "../config/Config";
import {useAuth} from "../context/AuthContext";

import './Login.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showForgottenPassword, setShowForgottenPassword] = useState(false);
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const {t} = useTranslation();
    const {login} = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const {data} = await axiosInstance.post(API.login.url, {email, password, stayLoggedIn});
            const {accessToken, refreshToken} = data;
            login(accessToken, refreshToken);
        } catch (e) {
            console.log(e);
            toast.error(e.response.data.errorCode ? t(e.response.data.errorCode) : e.response.data);
        }
        // Zde implementujte logiku pro odeslání přihlašovacích údajů
    };

    const handleForgottenPassword = () => {
        setShowForgottenPassword(true);
    };

    // if (showForgottenPassword) {
    //     return <ForgottenPassword/>;
    // }

    return (<>
            <ToastContainer style={{width: "50%"}}/>
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    <Forms.Text
                        type="email"
                        value={email}
                        onChange={(_, val) => setEmail(val)}
                        required
                        label="E-mail"
                        ref={inputRef}
                    />
                    <Forms.Text password
                                label="Heslo"
                                value={password}
                                onChange={(_, val) => setPassword(val)}
                                required
                    />
                    <Forms.Checkbox
                        label="Zůstat přihlášen?"
                        value={stayLoggedIn}
                        onChange={(e) => setStayLoggedIn(!stayLoggedIn)}
                    />
                    <Forms.Button type="submit" label="Login"/>
                    {/*<p className="forgotten-password" onClick={handleForgottenPassword}>*/}
                    {/*    Zapomenuté heslo*/}
                    {/*</p>*/}
                </form>
            </div>
        </>
    );
};

export default Login;
