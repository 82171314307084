import React, {useState} from "react";
import Button from "../../../bricks/forms/Button";
import {API} from "../../../config/Config";
import {useAppData} from "../../../context/AppDataContext";
import {axiosInstance} from "../../../bricks/axios";
import Forms from "../../../bricks/form/Forms";
import {onChange} from "../../../config/Tools";

import "./AppConfigModal.css";

const AppConfigModalContent = ({onHide}) => {
    const {appConfig, setAppConfig, setSuccess, setError} = useAppData();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(appConfig);

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const {method, url} = API.updateAppConfig;
            const {data} = await axiosInstance[method](url, formData);
            setAppConfig(data);
            setSuccess("Konfigurace aplikace úspěšně upravena")
            onHide();
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return formData && (
        <form onSubmit={handleSubmit}>
            <div className="modal-body">
                <h4>Základní nastavení</h4>
                <Forms.Select name="actualPeriod" label="Aktuální perioda" disabled={isLoading}
                              options={formData.periodList.map(period => ({label: period, value: period}))}
                              onChange={onChange(setFormData)} value={formData.actualPeriod}/>
                <Forms.Text name="bankAccount" label="Číslo účtu" onChange={onChange(setFormData)}
                            disabled={isLoading} value={formData.bankAccount}/>
                <Forms.Number name="coursePrice" label="Záloha na kurz" onChange={onChange(setFormData)}
                              disabled={isLoading} value={formData.coursePrice}/>
                <Forms.NestedForm value={formData.smsConfig} name="smsConfig" onChange={onChange(setFormData)}>
                    <Forms.Text name="smsAPIClientId" label="SMS Client ID"/>
                    <Forms.Text name="smsAPIClientSecret" label="SMS Client Secret"/>
                    <Forms.Number name="smsPrice" label="Cena SMS"/>
                    <Forms.Checkbox name="smsBeforeCourseStart" label="SMS před začátkem kurzu"/>
                    <Forms.Checkbox name="smsAfterCourseRegistration" label="SMS po registraci do kurzu"/>
                    <Forms.Number name="sendSmsBeforeCourseDelay" label="Časový předstih v hodinách pro zaslání SMS o začátku kurzu"/>
                </Forms.NestedForm>
                <Forms.NestedForm value={formData.emailConfig} name="emailConfig" onChange={onChange(setFormData)}>
                    <Forms.Text name="emailSender" label="Odesílatel emailu"/>
                    <Forms.Checkbox name="emailAfterRegistration"/>
                    <Forms.Checkbox name="emailAfterCourseRegistration"/>
                    <Forms.Checkbox name="emailAfterCourseChange"/>
                    <Forms.Checkbox name="emailAfterPaymentCredited"/>
                    <Forms.Checkbox name="emailAfterPaymentRefundStateChange"/>
                    <Forms.Checkbox name="emailAfterIsAlternateStateChange"/>
                </Forms.NestedForm>

            </div>
            <div className="modal-footer">
                <Button onClick={onHide} dark label="Zavřít" disabled={isLoading}/>
                <Button onClick={handleSubmit} label="Uložit" disabled={isLoading}/>
            </div>
        </form>
    );
};

export default AppConfigModalContent;
