import React, { useState, useEffect } from "react";
import "./LevelEditor.css";

const LevelEditor = ({ levelPointsConfig, column, row, onRowChange }) => {
    const [selected, setSelected] = useState(row[column.key]?.map(val => `${val}`) || []);

    useEffect(() => {
        const validIds = levelPointsConfig.flatMap(lpc => lpc.config.map(level => level._id));
        setSelected(prevSelected => prevSelected.filter(id => validIds.includes(id)));
    }, [levelPointsConfig]);

    const handleCheckboxChange = e => {
        const value = e.target.value;
        setSelected(old => {
            let items = [...old];
            if (e.target.checked) {
                items.push(value);
            } else {
                items = items.filter(item => item !== value);
            }
            return items;
        });
    }

    const handleChange = () => {
        onRowChange({ ...row, [column.key]: selected.filter(item => item !== "") }, true);
    }

    return (
        <div className="LevelEditor">
            {levelPointsConfig.map(levelPointsConfig => {
                return <div key={`lvl-group-${levelPointsConfig._id}`}>
                    {levelPointsConfig.name}
                    {
                        levelPointsConfig.config.map(level => (
                            <div key={`${level._id}`}>
                                <input
                                    checked={selected.indexOf(level._id) !== -1}
                                    value={level._id}
                                    onChange={handleCheckboxChange}
                                    type="checkbox"
                                    name={level._id}
                                />
                                <label>{level.level} (oficial: {level.officialLevel})</label>
                            </div>
                        ))
                    }
                </div>;
            })}
            <button type="button" onClick={handleChange}>uložit</button>
        </div>
    );
}

export default LevelEditor;