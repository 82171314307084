import React, {useState} from "react";
import {useAppData} from "../../../context/AppDataContext";
import AppConfigModalContent from "./AppConfigModalContent";
import Modal from "../../../bricks/Modal";
import useTranslation from "../../../hooks/useTranslation";
import Forms from "../../../bricks/form/Forms";

import "./AppConfigAdministration.scss";

const AppConfigAdministration = () => {
    const {t} = useTranslation();
    const {appConfig} = useAppData();
    const [showModal, setShowModal] = useState(false);

    // const handleCreateTestDatabase = async () => {
    //     setIsLoading(true);
    //     try {
    //         await authService.fetch("api/private/createTestDatabase", {method: "POST"});
    //         setFlushCacheLink(`${process.env.REACT_APP_TEST_SERVER_API_URL}/administration/appConfig`);
    //         setIsLoading(false);
    //         window.alert('Databáze pro testovací prostředí aktualizována. Zbývá kliknout na odkaz níže a v testovací aplikaci kliknout na tlačítko "Obnovit cachce"');
    //     } catch (error) {
    //         setError(error);
    //         setIsLoading(false);
    //     }
    // };

    // const flushCache = async () => {
    //     setIsLoading(true);
    //     try {
    //         await authService.fetch("api/private/flushCache", {method: "POST"});
    //         setIsLoading(false);
    //         window.alert('Kopírování databáze z produkce na test dokončeno. Můžete testovat!');
    //     } catch (error) {
    //         setError(error);
    //         setIsLoading(false);
    //     }
    // };

    const getBoolValue = (bool) => {
        return bool ? "Ano" : "Ne";
    };

    const _renderContent = () => {
        const {emailConfig, bankAccount, coursePrice} = appConfig;
        return (
            <React.Fragment>
                <Modal show={showModal} onHide={() => setShowModal(false)} header={"Upravit nastavní aplikace"}>
                    <AppConfigModalContent/>
                </Modal>
                <h3>Základní nastavení</h3>
                <table className="table">
                    <tbody>
                    <tr>
                        <th scope="row">Číslo účtu</th>
                        <td>{bankAccount}</td>
                        <th scope="row">Záloha na kurz</th>
                        <td>{coursePrice}</td>
                    </tr>
                    </tbody>
                </table>

                {/*<table className="table">*/}
                {/*    <tbody>*/}
                {/*    <tr>*/}
                {/*        <th scope="row">Client ID</th>*/}
                {/*        <td>{smsAPIClientId}</td>*/}
                {/*        <th scope="row">Cena SMS (bez DPH)</th>*/}
                {/*        <td>{`${smsPrice.toFixed(2)} Kč`}</td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th scope="row">Client Secret</th>*/}
                {/*        <td>{smsAPIClientSecret}</td>*/}
                {/*        <th scope="row">Aktuální kredit</th>*/}
                {/*        <td>{actualCredit}</td>*/}
                {/*    </tr>*/}
                {/*    <tr>*/}
                {/*        <th scope="row">Odeslat sms před začátkem kurzu</th>*/}
                {/*        <td>{getBoolValue(smsBeforeCourseStart)} (v předstihu {sendSmsBeforeCourseDelay} h)*/}
                {/*        </td>*/}
                {/*        <th scope="row">{t("smsAfterCourseRegistration")}</th>*/}
                {/*        <td>{getBoolValue(smsAfterCourseRegistration)}</td>*/}
                {/*    </tr>*/}
                {/*    </tbody>*/}
                {/*</table>*/}

                <h3>Nastavení E-mailu</h3>
                <table className="table">
                    <tbody>
                    <tr>
                        <th scope="row">Název odesílatele</th>
                        <td>{emailConfig.emailSender}</td>
                        <th scope="row">{t('emailAfterCourseChange')}</th>
                        <td>{getBoolValue(emailConfig.emailAfterCourseChange)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t('emailAfterCourseRegistration')}</th>
                        <td>{getBoolValue(emailConfig.emailAfterCourseRegistration)}</td>
                        <th scope="row">{t('emailAfterRegistration')}</th>
                        <td>{getBoolValue(emailConfig.emailAfterRegistration)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t('emailAfterPaymentCredited')}</th>
                        <td>{getBoolValue(emailConfig.emailAfterPaymentCredited)}</td>
                        <th scope="row">{t('emailAfterPaymentRefundStateChange')}</th>
                        <td>{getBoolValue(emailConfig.emailAfterPaymentRefundStateChange)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t('emailAfterIsAlternateStateChange')}</th>
                        <td>{getBoolValue(emailConfig.emailAfterIsAlternateStateChange)}</td>
                    </tr>
                    </tbody>
                </table>
                {/*<Button label="Upravit" type="openModal" modalId="appConfigModal"/>*/}
                {/*{!process.env.REACT_APP_ENV &&*/}
                {/*<Button dark label="Přenést databázi na testovací prostředí" type="button"*/}
                {/*        onClick={_handleCreateTestDatabase}/>}*/}
                {/*{flushCacheLink &&*/}
                {/*<a target="_blank" rel="noopener noreferrer" href={flushCacheLink}>Obnovit cache</a>}*/}
                {/*{process.env?.REACT_APP_ENV === 'test' &&*/}
                {/*<Button dark label="Obnovit cache" type="button"*/}
                {/*        onClick={_flushCache}/>}*/}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="AppConfigAdministration">
                <div className="header">
                    <h2>Nastavení aplikace (perioda {appConfig?.actualPeriod})</h2>
                    <Forms.EditIcon onClick={() => setShowModal(true)}/>
                </div>
                {appConfig && _renderContent()}
            </div>
        </React.Fragment>
    );

};

export default AppConfigAdministration;
